import { Field, Input } from "@fluentui/react-components";
import Select from 'react-select';
import { FieldProps } from "formik";
import React from "react";

interface Option {
  label: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  options: Array<Option>;
  isMulti?: boolean;
  className?: string;
  placeholder?: string;
  label: string;
  width: string;
}

export const FormInput = ({ field, form, ...props} : any) => {
  const { label } = props;
  return <Field required label={label} style={{ marginTop: 5}}
          size='medium'>
          <Input {...field} {...props} style={{ width: props.width}}/>
        </Field>
}

export const FormError = ({ ...props } : any) => {
  const { children } = props;
  return <div style={{ fontWeight: 'bold', color:'red'}}>{children}</div>
}

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  label
}: CustomSelectProps) => {
  const onChange = (option: Option | Option[]) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    );
  };
  
  const getValue = () => {
    if (options && field.value) {
      return isMulti
        ? options.filter((option: Option) => field.value.indexOf(option.value) >= 0)
        : options.find((option: Option) => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as any);
    }
  };

  return (
    <Field required label={label} style={{ marginTop: 5 }}
          size='medium'>
    <Select
      className={className}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      styles={{
        menuPortal: (styles) => ({ ...styles, height: 150 }),
        menuList: (styles) => ({ ...styles, height: 150 }),
        menu: (styles) => ({ ...styles, height: 150 })
      }}
    />
    </Field>
  );
};