import { Text } from "@fluentui/react-components";
import { RotatingLines } from "react-loader-spinner";

const InlineLoader = () => {

  return (
    <>
      <div style={{width: '100%', height: '100%', zIndex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        <RotatingLines
          visible={true}
          width="40"
          strokeColor="#555ac0"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
        <Text as="h2" style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: '500', color: 'black',
          fontSize: '1rem', lineHeight: '1.3', display: 'flex', justifyContent:'center'}}>Please Wait...</Text>
      </div>
    </>
    );
}
export default InlineLoader;