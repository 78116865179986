import { Body1, Button, Caption1, Card, CardFooter, CardHeader, CardPreview, Checkbox, Input, Spinner, tokens } from "@fluentui/react-components";
import React from "react";
import { Add12Filled, Bot20Filled, Delete20Regular, Save20Regular, Edit16Regular } from "@fluentui/react-icons";
import { withNavigation } from "../common/hooks/WithNavigation";
import { connect } from "react-redux";
import { setConversation, setConversationList } from "../../store/slices/conversationHistorySlices";
import { setSelectedFiles } from "../../store/slices/fileSlices";
import { endpoints } from "../../store/apis/endpoints";
import axiosApi from "../../store/apis/axiosApi";
import { toast } from "react-toastify";
import { NavigateFunction } from "react-router-dom";
import { RootState } from "../../store";
import { AuthData, Conversation, ConversationPageData, ConversationState, File, FileState, LLMState } from "../../store/types";
import InfiniteScroll from "react-infinite-scroll-component";
import { setScoreCardLLM } from "../../store/slices/largeLanguageModelSlices";

interface conversationState {
  selectedCard: any;
  conversations: Array<Conversation>;
  conversationData: ConversationPageData;
  edit: boolean;
  convName: string;
}
interface ConversationHistoryProps {
  conv: ConversationState;
  file: FileState;
  userData: AuthData;
  llm: LLMState;
  setConversation(data: Conversation): void;
  getLLMDetail(id: string): void;
  setSelectedFiles(data: File): void;
  setConversationList(data: Array<Conversation>): void;
  deleteConversation(id: string): void;
  navigate: NavigateFunction;
  getAllConversation(page: number | null): void;
  changeConversationData(data: Conversation): void;
  getChatData(conversation: Conversation | null, llmId: string | undefined): void;
  setScoreCardLLM(data: Array<string>): void;
}
class ConversationHistory extends React.Component<ConversationHistoryProps, conversationState> {
  constructor(props: ConversationHistoryProps) {
    super(props)
    this.state = {
      selectedCard: this.props.conv.conversation,
      conversations: this.props.conv.conversationList,
      conversationData: this.props.conv.conversationPageData,
      edit: false,
      convName: ''
    }
  }
  componentDidUpdate(prevProps: Readonly<ConversationHistoryProps>, prevState: Readonly<conversationState>, snapshot?: any): void {
    if(prevProps.conv.conversationPageData.results !== this.props.conv.conversationPageData.results) {
      let items = prevProps.conv.conversationPageData.next !== this.props.conv.conversationPageData.next ? 
        this.props.conv.conversationPageData.previous === null ? new Set([...this.props.conv.conversationPageData.results]) :
        new Set([...Array.from(this.state.conversations), ...this.props.conv.conversationPageData.results]) :
        new Set([...this.props.conv.conversationPageData.results]) 
      this.setState({ conversationData : this.props.conv.conversationPageData ,
        conversations: Array.from(items)
      })
      this.props.setConversationList(Array.from(items));
    }
  }

  onSelectedChange = (data: Conversation) => {
    const { setConversation, changeConversationData, setScoreCardLLM, setSelectedFiles} = this.props;
    if(this.state.selectedCard?.id !== data.id) {
      this.setState({ edit: false, convName: ''})
      this.setState({selectedCard: data})
      setConversation(data);
      changeConversationData(data);
      setScoreCardLLM([]);
      let selectedDoc = this.props.file.docList.filter((v:any) => v.id === data.document);
      if(selectedDoc.length > 0)
        setSelectedFiles(selectedDoc[0]);
    }
  }

  updateConversation() {
    const { userData, conv, getAllConversation } = this.props;
    let payload = {
      url: endpoints.advisor.conversation + this.state.selectedCard.id + '/',
      method: 'PUT',
      headers: { Authorization : 'Bearer ' + userData.accessToken },
      body: {
        name: this.state.convName,
        user: userData.userId,
        llm: conv.conversation?.llm,
        configuration: conv.config?.id,
        document: conv.conversation?.document
      }
    } 
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        this.setState({edit: false, convName: ''})
        getAllConversation(0);
      } else {
        toast.error("Error Occurred: " + res.body.data ? res.body.data : res.body);
      }
    });
  }

  render() {    

    return (
      <Card style={{ width: '100%' }}>
        <CardHeader
          header={
            <Body1 style={{display:'flex', flexDirection:'row', alignContent:'center', width:'100%'}}>
              <Caption1 style={{flex: 3, margin: 'auto'}}>Last updated: 5h ago · {this.state.conversations.length} Items</Caption1>
              <div style={{display:'flex', flexDirection:'row', alignContent:'center'}}>
                <Button icon={<Delete20Regular style={{flex: 1}}/>} 
                  onClick={() => {this.props.deleteConversation(this.state.selectedCard?.id)}}
                  disabled={this.state.selectedCard === null || Object.keys(this.state.selectedCard).length === 0}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Delete"
                  data-tooltip-place="top"
                />
              </div>
            </Body1>
          }
        />
        
        <CardPreview style={{
          display: 'list-item',
          height: '80%',
          overflow: 'auto'
        }}>
          <InfiniteScroll
              dataLength={this.state.conversationData.next === null ? 5 : (this.state.conversationData.next - 1)*5}
              hasMore={this.state.conversationData.next !== null}
              loader={<div><Spinner size='small' style={{height: 'inherit'}}/></div>}
              next={() => {
                this.props.getAllConversation(this.state.conversationData.next)
              }}
              height={'100%'}
            >
          {this.state.conversations.map((data) => (
            <Card
            key={data.id.toString()}
            style={{
              height: "fit-content",
              margin: "10px",
              width: 'calc(100% - 20px)'
            }}
            selected={data.id === this.state.selectedCard?.id}
            onSelectionChange={() => {this.onSelectedChange(data)}}
            floatingAction={
              <Checkbox onChange={() => {this.onSelectedChange(data)}} checked={data.id === this.state.selectedCard?.id} />
            }
          >
            <CardHeader
              image={
                <Bot20Filled fontSize={16} />
              }
              header={
                <div style={{display: 'flex', justifyContent: 'space-between', width: 'calc(100% - 35px)'}}>
                {data.id === this.state.selectedCard?.id && this.state.edit ? 
                <>
                  <Input size="small" onChange={(e) => this.setState({ convName : e.target.value})}/>
                  <Save20Regular onClick={this.updateConversation.bind(this)}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Save"
                    data-tooltip-place="top"
                  />
                </>
                : 
                <>
                  <b>{data.name}</b>
                  <Edit16Regular onClick={() => this.setState({ edit: true})}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Edit"
                    data-tooltip-place="top"
                  />
                </>
                }
                </div>
              }
              description={
                <Caption1 style={{ display: 'flex', flexDirection:'column', color: tokens.colorNeutralForeground3 }}>
                  <span>&gt; {data.id}</span>
                  <span>Last updated at {new Date(data.modified_at).toDateString()}</span>
                </Caption1>
              }
            />
          </Card>
          ))}
          </InfiniteScroll>
        </CardPreview>

        <CardFooter style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-around',
        }}>
          <Button icon={<Add12Filled fontSize={16} />} onClick={() => this.props.navigate('/easy-to-start')}>Create New Conversation</Button>
        </CardFooter>
      </Card>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return {
    userData: state.user,
    conv: state.conversationHistory,
    file: state.files,
    llm: state.largeLanguageModel
  };
}
const mapDispatchToProps = { setConversation, setSelectedFiles, setConversationList, setScoreCardLLM };
export default connect(mapStateToProps,mapDispatchToProps)(withNavigation(ConversationHistory));

