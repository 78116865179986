import React from "react";

import { connect } from "react-redux";
import { setCurrentLink } from "../../../store/slices/userSlices";
import { withNavigation } from "../../common/hooks/WithNavigation";
import { Divider, MessageBar, MessageBarBody, MessageBarGroup, RatingDisplay, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, TableRowId, typographyStyles } from "@fluentui/react-components";
import { endpoints } from "../../../store/apis/endpoints";
import axiosApi from "../../../store/apis/axiosApi";
import { toast } from "react-toastify";
import { RootState } from "../../../store";
import { AuthData, LLMState } from "../../../store/types";
import InlineLoader from "../../common/components/InlineLoader";


interface ScoreTableState {
  scoreCards: any[];
  selectedRows: Set<TableRowId>;
  llm_ids: Array<String>;
  loader: boolean;
  isError: boolean;
}

interface ScoreTableProps {
  llm: LLMState;
  userData: AuthData;
  type: string;
}

class ScoreTable extends React.Component<ScoreTableProps, ScoreTableState>{
  constructor(props: ScoreTableProps) {
    super(props);
    this.state = {
      llm_ids: this.props.llm.scoreCardLLMs,
      scoreCards: this.initialData,
      selectedRows: new Set<TableRowId>([1]),
      loader: false,
      isError: false
    }
  }

  initialData = this.props.type === "GLOBAL" ? [
    {
      "parameter": "Infrastructure & Investment"
    },
    {
      "parameter": "Training & Finetuning"
    },
    {
      'parameter': "Performance & Capabilities"
    },
    {
      "parameter": "Security & Risks"
    },
    {
      "parameter": "Relevance & Quality"
    }
  ] : [{
      "parameter": "Clarity"
    },
    {
      "parameter": "Accuracy"
    },
    {
      "parameter": "Coherence"
    },
    {
      "parameter": "Speed"
    }];

  componentDidMount(): void {
    this.getScoreCardTable();
  }

  componentDidUpdate(prevProps: Readonly<ScoreTableProps>, prevState: Readonly<ScoreTableState>, snapshot?: any): void {
    if(prevProps.llm.scoreCardLLMs !== this.props.llm.scoreCardLLMs) {
      this.setState({
        llm_ids: this.props.llm.scoreCardLLMs
      }, () => this.getScoreCardTable());
    }
    if(prevProps.type !== this.props.type) {
      this.getScoreCardTable();
    }
  }

  getScoreCardTable() {
    this.setState({loader: true})
    if(this.state.llm_ids.length > 0) {
      const { userData } = this.props;
      let payload = {
        url: `${endpoints.scoreCard.table}?llm_ids=${this.state.llm_ids.join(',')}&type=${this.props.type}`,
        method: 'GET',
        headers: { Authorization : 'Bearer ' + userData.accessToken }
      }
      axiosApi(payload).then(res => {
        if(res.isSuccess) {
          // this.updateDataValues(res.body.data);
          if(this.state.llm_ids.length > 0)
            this.setState({ scoreCards: res.body.data, loader: false });
          else
            this.setState({ scoreCards : this.initialData, loader: false })
        } else {
          toast.error(res.body.data ? res.body.data.message : res.body);
          this.setState({ loader: false, isError: true})
        }
      });
    } else this.setState({ scoreCards : this.initialData, loader: false})
  }

  // updateDataValues(responseData: Array<any>) {
  //   console.log("initail ", this,this.initialData);
  //   let items = this.initialData;
  //   if(responseData.length <=0) this.setState({ scoreCards : items})
  //   else {
  //     responseData.forEach(value => {
  //       let index = items.findIndex(v => v.parameter === value.parameter);
  //       if(index !== -1) {
  //         items[index] = value;
  //       }
  //     })
  //     this.setState({ scoreCards: items});
  //   }
  // }

  render() {
    let heardeCellText = typographyStyles.caption1Stronger;

    return (
      <div style={{ width: '100%', overflowX: 'auto'}}>
        {this.state.loader ? <InlineLoader/> : 
        this.state.isError ? 
        <MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
        <MessageBar intent="error" layout="multiline">
          <MessageBarBody>
            There seems to be an issue, Please try after sometime.
          </MessageBarBody>
        </MessageBar>
        </MessageBarGroup> :
        this.state.llm_ids.length === 0 ? <></> :  
        <Table noNativeElements size="extra-small" aria-label="Table with extra-small size">
          <TableHeader>
            <TableRow>
              {
                Object.keys(this.state.scoreCards[0]).map((column) => (
                  <TableHeaderCell style={heardeCellText} key={column}>
                    <TableCellLayout>
                    {(column === 'parameter') ? "Parameter" : column}
                    </TableCellLayout>
                  </TableHeaderCell>
                ))}

            </TableRow>
          </TableHeader>
          <TableBody>
            {this.state.scoreCards.map((item) => (
              <TableRow key={item.parameter}>
                {Object.keys(item).map((key) => (
                  <TableCell>
                    <TableCellLayout>
                      {item[key].rank ? '' : item[key]}
                      {(key !== 'parameter') && <RatingDisplay value={item[key].rank} style={{ marginLeft: '5px' }} />}

                    </TableCellLayout>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        }
        <Divider style={{ margin: '10px 0px'}}></Divider>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { llm: state.largeLanguageModel, userData: state.user };
}
const mapDispatchToProps = { setCurrentLink };

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(ScoreTable));