import { createSlice } from "@reduxjs/toolkit";
import { AuthData } from "../types";

const initialState: AuthData = {
  firstName: '',
  lastName: '',
  email: '',
  accessToken: '',
  refreshToken: '',
  isAuthenticated: false,
  currentLink: '/home',
  userId: '',
  industry: null,
  role: null,
  functionalArea: null,
  product: {
    id: "",
    created_at: "",
    modified_at: "",
    title: "",
    sub_title: "",
    description: "",
    image: "",
    slug: ""
  }
};
  
  export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      setFirstName: (state, action) => {
        state.firstName = action.payload;
      },
      setLastName: (state, action) => {
        state.lastName = action.payload;
      },
      setEmail: (state, action) => {
        state.email = action.payload;
      },
      setAccessToken: (state, action) => {
        state.accessToken = action.payload;
      },
      setRefreshToken: (state, action) => {
        state.refreshToken = action.payload;
      },
      setIsAuthenticated: (state, action) => {
        state.isAuthenticated = action.payload;
      },
      setCurrentLink: (state, action) => {
        state.currentLink = action.payload;
      },
      setUserId: (state, action) => {
        state.userId = action.payload;
      },
      setIndustry: (state, action) => {
        state.industry = action.payload;
      },
      setFunctionalArea: (state, action) => {
        state.functionalArea = action.payload;
      },
      setRole: (state, action) => {
        state.role = action.payload;
      },
      setProductData: (state, action) => {
        state.product = action.payload;
      },
      resetUserData: (state) => {
        Object.assign(state, initialState);
      },
    },
  });
  
  export const { setFirstName, setLastName, setEmail, setAccessToken, setRefreshToken, setIsAuthenticated,
    setCurrentLink, setUserId, resetUserData, setIndustry, setFunctionalArea, setRole, setProductData
   } = userSlice.actions;
  
  export default userSlice.reducer;