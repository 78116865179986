import { Text } from "@fluentui/react-components";
import { RotatingLines } from "react-loader-spinner";

const Loader = () => {

  return (
    <>
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', display: 'flex', flexDirection: 'column',
        justifyContent: 'center', height: '100vh', background: 'rgb(111,111,111,0.2)', zIndex:10, alignItems: 'center'
        }}>
          <RotatingLines
             visible={true}
             width="80"
             strokeColor="#555ac0"
             strokeWidth="5"
             animationDuration="0.75"
             ariaLabel="rotating-lines-loading"
          />
          <Text as="h3" style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: '500', color: 'black',
            fontSize: '1.2rem', lineHeight: '1.3', display: 'flex', justifyContent:'center'}}>Please Wait...</Text>
      </div>
    </>
    );
}
export default Loader;