import { createSlice } from "@reduxjs/toolkit";
import { LLMState } from "../types";

const initialState: LLMState = {
  selectedLLM: [],
  scoreCardLLMs: [],
  llmList: {
    count: 0,
    next: 0,
    previous: 0,
    results: []
  },
  allLLMList: {
    count: 0,
    next: 0,
    previous: 0,
    results: []
  },
  parameters : []
};
  
  export const largeLanguageModelSlice = createSlice({
    name: "largeLanguageModel",
    initialState,
    reducers: {
      setSelectedLLM: (state, action) => {
        state.selectedLLM = action.payload;
      },
      setScoreCardLLM: (state, action) => {
        state.scoreCardLLMs = action.payload;
      },
      setLLMList: (state, action) => {
        state.llmList.count = action.payload.count;
        state.llmList.next = action.payload.next;
        state.llmList.previous = action.payload.previous;
        state.llmList.results = action.payload.results;
      },
      setAllLLMList: (state, action) => {
        state.allLLMList.count = action.payload.count;
        state.allLLMList.next = action.payload.next;
        state.allLLMList.previous = action.payload.previous;
        state.allLLMList.results = action.payload.results;
      },
      resetLLMList: (state) => {
        state.llmList = initialState.llmList;
      },
      resetAllLLMList: (state) => {
        state.allLLMList = initialState.allLLMList;
      },
      setParameters: (state, action) => {
        state.parameters = action.payload
      },
      resetLLMState: (state) => {
        Object.assign(state, initialState);
      }
    },
  });
  
  export const { setSelectedLLM, setLLMList, setAllLLMList, resetLLMList, resetAllLLMList,
    setParameters, setScoreCardLLM, resetLLMState } = largeLanguageModelSlice.actions;
  
  export default largeLanguageModelSlice.reducer;