import { Body1, Button, Caption1, Card, CardHeader, CardPreview, Spinner } from "@fluentui/react-components";
import React from "react";
import { connect } from "react-redux";
import { CommentAdd24Regular } from "@fluentui/react-icons";
import { endpoints } from "../../store/apis/endpoints";
import axiosApi from "../../store/apis/axiosApi";
import { toast } from "react-toastify";
import { setConversation } from "../../store/slices/conversationHistorySlices";
import { setDocList, setSelectedFiles } from "../../store/slices/fileSlices";
import { RootState } from "../../store";
import { AuthData, Conversation, ConversationState, FilePageData, FileState, LLM, LLMState } from "../../store/types";
import InfiniteScroll from "react-infinite-scroll-component";

interface DocumentsProps {
  file: FileState;
  userData: AuthData;
  llm: LLMState;
  conv: ConversationState;
  setConversation(data: Conversation): void;
  getDocList(page: number): void;
  getAllConversation(): void;
  setDocList(data: Array<File>): void;
}
interface DocumentsState {
  selectedFiles: any;
  docPageData: FilePageData;
  docList: Array<File>;
}
class Documents extends React.Component<DocumentsProps | any, DocumentsState> {
  constructor(props: DocumentsProps | any) {
    super(props);
    this.state = {
      selectedFiles: this.props.file.selectedFiles,
      docList: this.props.file.docList,
      docPageData: this.props.file.docPageData
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<DocumentsState>, snapshot?: any): void {
    if(this.state.selectedFiles !== this.props.file.selectedFiles)
      this.setState({selectedFiles: this.props.file.selectedFiles})
    if(prevProps.file.docPageData.results !== this.props.file.docPageData.results) {
      let items = prevProps.file.docPageData.next !== this.props.file.docPageData.next ? 
        this.props.file.docPageData.previous === null ? new Set([...this.props.file.docPageData.results]) :
        new Set([...Array.from(this.state.docList), ...this.props.file.docPageData.results]) :
        new Set([...this.props.file.docPageData.results]) 
      this.setState({ docPageData : this.props.file.docPageData ,
        docList: Array.from(items)
      })
      this.props.setDocList(Array.from(items));
    }
  }

  componentDidMount(): void {
      this.props.getDocList(0);
  }
  
  onSelectedChange = (data: any) => {
    this.setState({selectedFiles: data})
  }

  createConversation(data: any) {  
    this.setState({selectedFiles: data});  
    const {userData, llm, setConversation, getAllConversation, conv } = this.props;
    let payload = {
      url: endpoints.advisor.conversation,
      method: 'POST',
      headers: { Authorization : 'Bearer ' + userData.accessToken },
      body: {
        user: userData.userId,
        configuration: conv.config?.id,
        document: data.id,
        llm: llm.selectedLLM.map((data: LLM) => { return data.id}),
      }
    } 
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        setConversation(res.body.data);
        getAllConversation();
      } else {
        toast.error("Error Occurred: " + res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  render() {
    return (
      <Card style={{ width: '100%' }}>
        <CardHeader
          header={
            <Body1 style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', width: '100%' }}>
              <Caption1>3.75GB · {this.state.docPageData.count} Files</Caption1>
            </Body1>
          }
        />
        <CardPreview as="div" style={{
          overflow: 'auto'
        }}>
          <InfiniteScroll
            dataLength={this.state.docPageData.next === null ? 5 : (this.state.docPageData.next - 1) * 5}
            hasMore={this.state.docPageData.next !== null}
            loader={<div><Spinner size='small' style={{height: 'inherit'}}/></div>}
            next={() => {
              this.props.getDocList(this.state.docPageData.next)
            }}
            height={'100%'}
          >
            {this.state.docList.map((file: any) => (
              <Card
                key={file.id.toString()}
                style={{
                  height: "fit-content", display:'flex', flexDirection:'row', justifyContent: 'space-between',
                  margin: "5px 10px", padding: 5, width: 'auto'
                }}
                selected={file.id === this.state.selectedFiles?.id}
              >
                <div style={{ display: 'flex', flexDirection:'row', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection:'column', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <b>{file.filename}</b>
                      <Caption1 style={{ display: 'flex', flexDirection:'row' }}>
                      <span>Uploaded on {new Date(file.created_at).toDateString()}</span>
                    </Caption1>
                    </div>
                    <Button icon={<CommentAdd24Regular />} onClick={() => {this.createConversation(file)}}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Create New Conversation"
                    data-tooltip-place="top"
                  /></div>
              </Card>
              ))}
            </InfiniteScroll>
          <>
            
          </>
        </CardPreview>
      </Card >
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: state.user,
    conv: state.conversationHistory,
    file: state.files,
    llm: state.largeLanguageModel
   };
}
const mapDispatchToProps = { setConversation, setSelectedFiles, setDocList };
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
