import React from "react";
import { RootState } from "../../../store";
import { setCurrentLink } from "../../../store/slices/userSlices";
import { withNavigation } from "../../common/hooks/WithNavigation";
import { connect } from "react-redux";
import {
    Radar,
    RadarChart,
    PolarGrid,
    Legend,
    PolarAngleAxis,
} from "recharts";
import { MessageBar, MessageBarBody, MessageBarGroup } from "@fluentui/react-components";
import { AuthData, LLMState } from "../../../store/types";
import { endpoints } from "../../../store/apis/endpoints";
import axiosApi from "../../../store/apis/axiosApi";
import { toast } from "react-toastify";
import InlineLoader from "../../common/components/InlineLoader";
var randomColor = require('randomcolor');

interface ScoreCardProps {
    userData: AuthData;
    llm: LLMState;
	type: string;
}
interface ScoreCardSate {
    data: any[];
    llm_ids: Array<String>;
    loader: boolean;
    isError: boolean;
}
class ScoreCard extends React.Component<ScoreCardProps, ScoreCardSate> {
    constructor(props: ScoreCardProps) {
        super(props);
        this.state = {
            llm_ids: this.props.llm.scoreCardLLMs,
            data: this.initialData,
            loader: false,
            isError: false
        }
    }
		
    initialData = [];

	componentDidMount(): void {
		this.getScoreCard();
	}

    componentDidUpdate(prevProps: Readonly<ScoreCardProps>, prevState: Readonly<ScoreCardSate>, snapshot?: any): void {
        if(prevProps.llm.scoreCardLLMs !== this.props.llm.scoreCardLLMs) {
            this.setState({
                llm_ids: this.props.llm.scoreCardLLMs
            }, () => this.getScoreCard());
        }
        if(prevProps.type !== this.props.type) {
            this.getScoreCard();
        }
    }

    getScoreCard() {
      this.setState({loader: true})
			if(this.state.llm_ids.length > 0) {
				const { userData } = this.props;
				let payload = {
					url: `${endpoints.scoreCard.graph}?llm_ids=${this.state.llm_ids.join(',')}&type=${this.props.type}`,
					method: 'GET',
					headers: { Authorization : 'Bearer ' + userData.accessToken }
				}
				axiosApi(payload).then(res => {
					if(res.isSuccess) {
						// this.updateDataValues(res.body.data);
						if(this.state.llm_ids.length > 0)
							this.setState({ data: res.body.data, loader: false });
						else
							this.setState({ data : this.initialData, loader: false })
					} else {
						toast.error(res.body.data ? res.body.data.message : res.body);
                        this.setState({ loader: false, isError: true});
					}
				});
			} else this.setState({ data : this.initialData, loader: false })
		}

		// updateDataValues(responseData: Array<any>) {
		// 	let items = this.state.data;
		// 	if(responseData.length <=0) this.setState({ data : this.initialData})
		// 	else {
		// 		responseData.forEach(value => {
		// 			let index = items.findIndex(v => v.parameter === value.parameter);
		// 			if(index !== -1) {
		// 				items[index] = value;
		// 			}
		// 		})
		// 		this.setState({ data: items});
		// 	}
		// }

    getValues() {
        let arr: any[] = [];

        var colorsFill = randomColor({
            count: 4,
            luminosity: 'dark'
        });
        Object.keys(this.state.data[0]).forEach(key => {
            if (key !== "parameter" && key !== "fullMark") {
                arr.push(<Radar
                    name={key}
                    dataKey={key}
                    fillOpacity={0}
                    stroke={colorsFill.pop()}
                    strokeWidth={3}
                />);
            }
        }
        )
        return arr;
    }


    render() {
        
        return (
            <>
                        {this.state.loader ? <InlineLoader/> : 
                            this.state.isError ? 
                            <MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
                            <MessageBar intent="error" layout="multiline">
                                <MessageBarBody>
                                There seems to be an issue, Please try after sometime.
                                </MessageBarBody>
                            </MessageBar>
                            </MessageBarGroup> :
                        <>
                         {this.state.data.length > 0 && (<>
                            <RadarChart
                                cx={175}
                                cy={150}
                                style={{scale:0.9}}
                                outerRadius={'60%'}
                                width={350}
                                height={350}
                                data={this.state.data}
                            >
                                
                                <PolarGrid stroke="black" fill="white"/>
                                <PolarAngleAxis stroke="black" dataKey="parameter" />
                                {this.getValues()}
                                <Legend verticalAlign="top"/>
                            </RadarChart>
                            </>)}
                            {(this.state.llm_ids.length === 0 || this.state.data.length === 0) && (
                                <MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
                                <MessageBar intent="warning" layout="multiline">
                                  <MessageBarBody>
                                    {this.props.userData.product.title.substring(0, this.props.userData.product.title.indexOf(","))}: {this.state.llm_ids.length === 0 ? 
                                    "Please choose one or more LLMs to view score card." :
                                    "Oops! no Data available at the moment please tra again."}
                                  </MessageBarBody>
                                </MessageBar>
                              </MessageBarGroup>
                            )}
                            </>}
                    </>
        )
    }
}
const mapStateToProps = (state: RootState) => {
    return { userData: state.user, llm: state.largeLanguageModel };
}

const mapDispatchToProps = { setCurrentLink };

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(ScoreCard));
