import React from "react";
import GridLayout from "react-grid-layout";
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import ChatWindow from "./ChatWindow";
import { ConversationChats, LLM } from "../../store/types";
import InlineLoader from "../common/components/InlineLoader";
interface ChatGridProps {
  convChats: Array<ConversationChats>;
  llmList: Array<LLM>;
}
interface ChatGridState {
  layout: Array<any>;
  convChats: Array<ConversationChats>;
  loader: boolean;
}
class ChatGrid extends React.Component<ChatGridProps, ChatGridState> {
  constructor(props: ChatGridProps) {
    super(props);
    this.state = {
      layout: [],
      convChats: this.props.convChats,
      loader: true
    }
  }

  componentDidMount(): void {
    this.changelayout();
  }

  changelayout() {
    let x=0;
    let layout = this.state.convChats.map((data) => {
      let a = { i: data.llmId, x: x, y: 0, w: 4, h: 10 };
      x=x+4;
      return a;
    })
    this.setState({ layout: layout});
    if(layout.length > 0) 
      this.setState({ loader: false})
  }

  componentDidUpdate(prevProps: Readonly<ChatGridProps>, prevState: Readonly<ChatGridState>, snapshot?: any): void {
    if(prevProps !== this.props) {  
      this.changelayout();
      this.setState({ convChats : this.props.convChats , loader: false})
    }
  }

  getLLMChat(llmId: string) {
    let item = this.state.convChats.filter(data => data.llmId === llmId);
    return item[0].chat;
  }

  getLLMName(llmId: string) {
    let item = this.props.llmList.filter(v => v.id === llmId);
    return item.length > 0 ? item[0].display_name : '';
  }

  render() {
    
    return (<>
      {this.state.loader ? <InlineLoader/> :
      <GridLayout
        className="layout"
        layout={this.state.layout}
        cols={12}
        rowHeight={30}
        width={1300}
        draggableHandle=".react-grid-dragHandleExample"
      >
        {this.state.layout.map((v: any) => (
          <div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 5px 2px', backgroundColor: 'white',
            display: 'flex', flexDirection: 'column'
            }} key={v.i}>
            <ChatWindow messages={this.getLLMChat(v.i)} llmId={v.i} llmName={this.getLLMName(v.i)}/>
          </div>
        ))}
      </GridLayout>
      }</>
    );
  }
}
export default ChatGrid;