import React from "react";
import { setScoreCardLLM, setSelectedLLM } from "../../store/slices/largeLanguageModelSlices";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { AuthData, LLM, LLMPageData, LLMState } from "../../store/types";
import { DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, TableCellLayout, TableColumnDefinition, TableRowId, createTableColumn } from "@fluentui/react-table";
import { Avatar } from "@fluentui/react-avatar";
import { Card, CardHeader } from "@fluentui/react-card";
import InlineLoader from "../common/components/InlineLoader";
import { MessageBar, MessageBarBody, MessageBarGroup, Persona } from "@fluentui/react-components";

interface LLMListProps {
  setShowEval(data: any): void;
  setSelectedLLM(data: Array<LLM>): void;
  setScoreCardLLM(data: Array<String>): void;
  llmList: LLMPageData;
  llm: LLMState;
  userData: AuthData;
  isError: boolean;
}
interface LLMListState {
  selected: Array<LLM>;
  selectedRows: Set<TableRowId>
  loader: boolean;
}

const columns: TableColumnDefinition<LLM>[] = [
  createTableColumn<LLM>({
    columnId: "display_name",
    compare: (a, b) => {
      return a.display_name.localeCompare(b.display_name);
    },
    renderHeaderCell: () => {
      return "Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout
          aria-disabled={!item.is_active}
          media={
            <Avatar
              aria-label={item.display_name}
              name={item.display_name}
              badge={{ status: item.is_active ? 'available': 'offline' }}
            />
          }
        >
          {item.display_name}
        </TableCellLayout>
      );
    },
  }),
  // createTableColumn<LLM>({
  //   columnId: "type",
  //   compare: (a, b) => {
  //     return a.type.localeCompare(b.type);
  //   },
  //   renderHeaderCell: () => {
  //     return "Type";
  //   },
  //   renderCell: (item) => {
  //     return (
  //       <TableCellLayout>
  //         {item.type}
  //       </TableCellLayout>
  //     );
  //   },
  // }),
  createTableColumn<LLM>({
    columnId: "license",
    compare: (a, b) => {
      return a.licence.localeCompare(b.licence);
    },
    renderHeaderCell: () => {
      return "License";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout
          aria-disabled={!item.is_active}
        >
          {item.licence}
        </TableCellLayout>
      );
    },
  })
  // createTableColumn<LLM>({
  //   columnId: "language_covered",
  //   compare: (a, b) => {
  //     return a.rating - b.rating;
  //   },
  //   renderHeaderCell: () => {
  //     return "Language Covered";
  //   },
  //   renderCell: (item) => {
  //     return (
  //       <TableCellLayout >
  //         {item.language_covered}
  //       </TableCellLayout>
  //     );
  //   },
  // }),
];
class LLMList extends React.Component<LLMListProps, LLMListState, { selectedRows: Set<TableRowId> }>{
  constructor(props: LLMListProps) {
    super(props);
    this.state = {
      selected: this.props.llm.selectedLLM,
      selectedRows: new Set<TableRowId>(this.props.llm.selectedLLM.map(v => v.id)),
      loader: true
    }
  }

  componentDidUpdate(prevProps: Readonly<LLMListProps>, prevState: Readonly<LLMListState>, snapshot?: { selectedRows: Set<TableRowId>; } | undefined): void {
    if(prevProps.llmList.results !== this.props.llmList.results) {
      this.setState({ loader: false})
    }
  }

  onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
      var rowsArray = Array.from(data.selectedItems);
      var selectedLLM = this.props.llmList.results.filter(llm => rowsArray.find(row => llm.id === row) && llm.is_active)
      this.props.setSelectedLLM(selectedLLM);
      this.props.setScoreCardLLM(selectedLLM.map(v => v.id));
      this.setState({ selectedRows: data.selectedItems });
  };

  render() {
    return (
      <Card style={{width: '100%', height: '100%',  margin: '5px', overflow: 'auto' }}>
        <CardHeader
          header={
              <Persona
                size="extra-large" 
                name={this.props.userData.product.title}
                tertiaryText={'"Choose the LLM(s) for me to use!"'}
                avatar={{
                  image: {
                    src: this.props.userData.product.image
                }}}
              />  
          }
        />
        <DataGrid
          items={this.props.llmList.results}
          columns={columns}
          selectedItems={this.state.selectedRows}
          sortable
          selectionMode="multiselect"
          onSelectionChange={this.onSelectionChange}
          getRowId={(item) => item.id}
          focusMode="composite"
        >
          <DataGridHeader>
            <DataGridRow
              selectionCell={{
                checkboxIndicator: { "aria-label": "Select all rows" },
              }}
            >
              {({ renderHeaderCell }) => (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          {this.state.loader ? <InlineLoader/> : 
            this.props.isError ? 
            <MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
              <MessageBar intent="error" layout="multiline">
                <MessageBarBody>
                  There seems to be an issue, Please try after sometime.
                </MessageBarBody>
              </MessageBar>
            </MessageBarGroup> :
            <DataGridBody<LLM>>
              {({ item, rowId }) => (
                <DataGridRow<LLM>
                  key={rowId}
                  selectionCell={{
                    checkboxIndicator: { "aria-label": "Select row", disabled : !item.is_active },
                  }}
                  style={{ pointerEvents: item.is_active ? 'auto' : 'none'}}
                >
                  {({ renderCell }) => (
                    <DataGridCell>{renderCell(item)}</DataGridCell>
                  )}
                </DataGridRow>
              )}
            </DataGridBody>
          }
        </DataGrid>
      </Card>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { file: state.files, userData: state.user, llm: state.largeLanguageModel };
}
const mapDispatchToProps = { setSelectedLLM, setScoreCardLLM };
export default connect(mapStateToProps, mapDispatchToProps)(LLMList);
