import { createSlice } from "@reduxjs/toolkit";
import { FileState } from "../types";

const initialState: FileState = {
  uploadedFiles : [],
  docPageData: {
    count: 0,
    next: 0,
    previous: 0,
    results: []
  },
  docList: [],
  selectedFiles : []
};

export const fileSlices = createSlice({
  name: "files",
  initialState,
  reducers: {
    setUploadedFiles: (state, action) => {
      state.uploadedFiles = action.payload;
    },
    setDocPageData: (state, action) => {
      state.docPageData.count = action.payload.count;
      state.docPageData.next = action.payload.next;
      state.docPageData.previous = action.payload.previous;
      state.docPageData.results = action.payload.results;
    },
    setDocList: (state, action) => {
      state.docList = action.payload
    },
    resetUploadadFiles: (state) => {
      state.uploadedFiles = []
    },
    setSelectedFiles: (state, action) => {
     state.selectedFiles = action.payload;
    },
    resetFileState: (state) => {
      Object.assign(state, initialState);
    }
  },
});

export const { setUploadedFiles, setDocList, resetUploadadFiles, setSelectedFiles, setDocPageData, resetFileState } = fileSlices.actions;

export default fileSlices.reducer;