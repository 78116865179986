import React from "react";

import {
  Home24Filled,
  ContentView24Filled,
} from "@fluentui/react-icons";
import { Avatar, Button, Caption1, Persona, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { connect } from "react-redux";
import { withNavigation } from "../hooks/WithNavigation";
import { resetUserData, setCurrentLink } from "../../../store/slices/userSlices";
import { Tooltip } from "react-tooltip";
import { resetLLMState } from "../../../store/slices/largeLanguageModelSlices";
import { resetFileState } from "../../../store/slices/fileSlices";
import { resetConvState } from "../../../store/slices/conversationHistorySlices";
const logo = require('../../../assets/images/Translator.png');
const testDriveLogo = require('../../../assets/images/TestDriveLogo.png');


interface AppBarHeadState {
  anchorElNav: any;
  anchorElUser: any;
  active: string;
  isPopoverOpen: boolean
}

class AppBarHead extends React.Component<any, AppBarHeadState>{
  constructor(props: any) {
    super(props);
    this.state = {
      anchorElNav: null,
      anchorElUser: null,
      active: "home",
      isPopoverOpen: false
    };
  }

  componentDidMount(): void {
    var currentPath = this.props.location.pathname.replace('/', '');
    this.setState({
      active: currentPath,
    });
  }

  updateState() {
    // Changing state

  }
  handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElNav: event.currentTarget,
    });

  };
  handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElUser: event.currentTarget,
    });
  };

  handleCloseNavMenu = () => {
    this.setState({
      anchorElNav: null,
    });
  };

  handleCloseUserMenu = () => {
    this.setState({
      anchorElUser: null,
    });
  };

  handleLogout() {
    const {resetUserData, resetLLMState, resetFileState, resetConvState, navigate } = this.props;
    resetUserData();
    resetLLMState();
    resetFileState();
    resetConvState();
    navigate('/sign-in')
  }

  render() {
    let styles = {
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      backgroundColor: "smoke-white",
      color: "black",
    }
    const { firstName, lastName, isAuthenticated } = this.props.userData;
    return (
      <>
        <Toolbar aria-label="Vertical Button" size="medium" style={styles}>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img
              style={{
                width: "170px",
                height: "50px",
              }}
              src={logo}
              alt="App Name Document"
            />
          </div>
          <div style={{ flex: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "flext-start" }}>
            <ToolbarButton vertical appearance={this.state.active === "home" ? "primary" : "subtle"} icon={<Home24Filled />}
              onClick={() => { this.props.navigate('/home'); this.setState({ active: 'home' }) }}
            >
              Home
            </ToolbarButton>
            <ToolbarButton vertical appearance={this.state.active === "my-workspace" ? "primary" : "subtle"} icon={<ContentView24Filled />}
              onClick={() => {
                this.setState({ active: 'my-workspace' })
                if (isAuthenticated) this.props.navigate('/my-workspace'); else {
                  this.props.navigate('/sign-in'); this.props.setCurrentLink('/my-workspace');
                }
              }}
            >
              My Workspace
            </ToolbarButton>
            {isAuthenticated && this.state.active === 'my-workspace' && <Persona
                size="extra-large" 
                name={this.props.userData.product.title}
                tertiaryText={'"Hurray! '+ firstName +' '+ lastName+', You are all set now, Happy Prompting!"'}
                style={{marginLeft: '30px'}}
                avatar={{
                  image: {
                    src: this.props.userData.product.image
                }}}
              ></Persona>}
          </div>
          <div style={{
            flex: 2, display: 'flex', flexDirection: 'row', marginRight: 20,
            alignItems: 'center', justifyContent: "end", gap: 15
          }}>
            {isAuthenticated ? <>
              <Avatar color="brand" name={firstName+' '+lastName} 
                size={40}
                data-tooltip-id="popover"
                data-tooltip-place="bottom"
              />
            </>
              : <Button appearance="primary" onClick={() => { this.props.navigate('/sign-in') }}>SIGN IN</Button>
            }
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <img
              src={testDriveLogo}
              alt="Test Drive Logo"
            />
            <div style={{fontSize: '8px', marginTop: '-26px', marginRight: '-13px'}}><em>Beta</em></div>
            <Caption1 style={{fontSize: '10px'}}>POWERED BY RECODE SOLUTIONS</Caption1>
            </div>
          </div>
        </Toolbar>
        <Tooltip id="popover"  style={{ backgroundColor: "#fff", boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 10px 3px'}} opacity={1} clickable>
          <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
            <Persona
              name={firstName+' '+lastName}
              secondaryText="Available"
              presence={{ status: "available" }} />
            <Button style={{marginTop: 10}} onClick={this.handleLogout.bind(this)}>
              LOGOUT
            </Button>
          </div>
        </Tooltip>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { userData: state.user };
}
const mapDispatchToProps = { setCurrentLink, resetUserData, resetLLMState, resetFileState, resetConvState };

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(AppBarHead));