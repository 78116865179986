import { createSlice } from "@reduxjs/toolkit";
import { ConversationState } from "../types";

const initialState: ConversationState = {
  config: null,
  conversationList: [],
  conversation: null,
  chat: [],
  conversationPageData: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};
  
  export const conversationHistorySlice = createSlice({
    name: "conversationHistory",
    initialState,
    reducers: {
      setConfig: (state, action) => {
        state.config = action.payload;
      },
      setConversationList: (state, action) => {
        state.conversationList = action.payload;
      },
      setConversation: (state, action) => {
        state.conversation = action.payload;
      },
      setChat: (state, action) => {
        state.chat = action.payload;
      },
      setConvPageData: (state, action) => {
        state.conversationPageData.count = action.payload.count;
        state.conversationPageData.next = action.payload.next;
        state.conversationPageData.previous = action.payload.previous;
        state.conversationPageData.results = action.payload.results;
      },
      resetConvState: (state) => {
        Object.assign(state, initialState);
      }
    },
  });
  
  export const { setConfig, setConversationList, setConversation, setChat, setConvPageData, resetConvState } = conversationHistorySlice.actions;
  
  export default conversationHistorySlice.reducer;