import * as React from 'react';
import { Card, Button } from '@fluentui/react-components';
import { LockClosed28Regular } from "@fluentui/react-icons";
import { connect } from 'react-redux';
import { setAccessToken, setEmail, setFirstName, setFunctionalArea, setIndustry, setIsAuthenticated, setLastName, setRefreshToken, setRole, setUserId } from '../../store/slices/userSlices';
import { endpoints } from '../../store/apis/endpoints';
import axiosApi from '../../store/apis/axiosApi';
import Loader from '../common/components/Loader';
import { Formik, Form, Field as FormikField, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FormError, FormInput } from '../common/components/FormikComp';
import { toast } from 'react-toastify';
import { withNavigation } from '../common/hooks/WithNavigation';

const signInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Please Enter Email'),
  password: Yup.string().required('Please Enter Password'),
});

interface SignInState {
  open: boolean;
  otp: string;
  email: string | undefined;
  password: string | undefined;
  loader: boolean
}

class SignIn extends React.Component<any, SignInState> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      otp: '',
      email: '',
      password: '',
      loader: false
    }
  }

  handleSubmit(event: any) {
    this.setState({
      email: event.email,
      password: event.password,
      loader: true
    })
    let payload = {
      url: endpoints.accounts.login,
      method: 'POST',
      body: event
    }
    axiosApi(payload).then(res => {
      console.log("res ", res);
      if(res.isSuccess) {
        const { given_name, surname, access, refresh, user_id, industry, functional_area, role } = res.body.data;
        const { currentLink } = this.props.userData;
        this.props.setFirstName(given_name);
        this.props.setLastName(surname);
        this.props.setEmail(event.email);
        this.props.setAccessToken(access);
        this.props.setRefreshToken(refresh);
        this.props.setIsAuthenticated(true);
        this.props.setUserId(user_id);
        this.props.setIndustry(industry);
        this.props.setFunctionalArea(functional_area);
        this.props.setRole(role);
        this.setState({loader: false, open: false});
        this.props.navigate(currentLink);
        toast.success('Sucessfully Logged In')
      } else {
        this.setState({ loader: false});
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  handleClose() {
    this.setState({open: false, loader: false});
  }

  // validateOtp() {
  //   this.setState({ loader: true});
  //   const { email, password, otp } = this.state;
  //   let payload = {
  //     url: endpoints.accounts.validate,
  //     method: 'POST',
  //     body: {
  //       email: email,
  //       password: password,
  //       otp: otp
  //     }
  //   }
  //   axiosApi(payload).then(res => {
  //     if(res.isSuccess) {
  //       const { given_name, surname, access, refresh, user_id } = res.body.data;
  //       const { currentLink } = this.props.userData;
  //       this.props.setFirstName(given_name);
  //       this.props.setLastName(surname);
  //       this.props.setEmail(email);
  //       this.props.setAccessToken(access);
  //       this.props.setRefreshToken(refresh);
  //       this.props.setIsAuthenticated(true);
  //       this.props.setUserId(user_id)
  //       this.setState({loader: false, open: false});
  //       this.props.navigate(currentLink);
  //       toast.success('Sucessfully Logged In')
  //     } else {
  //       this.setState({ loader: false});
  //       toast.error(res.body.data ? res.body.data.message : res.body);
  //     }
  //   });
  // }

  render() {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center', alignSelf:'center'}}>
          <div
            style={{ width:'30%', maxWidth: 400, minWidth: 350,
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Card style={{width:'100%', boxShadow: '0 0 2px rgba(0,0,0,0.24), 0 8px 16px rgba(0,0,0,0.28)'}}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center', borderRadius: '10000px', backgroundColor:'#4f52b2', padding:5,
                  }}>
                  <LockClosed28Regular color='#ffffff'/>
                </div>
                <h2 style={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: '400',
                  fontSize: '1.5rem', lineHeight: '1.334', margin:5}}>Sign In</h2>
              </div>
              <Formik
                initialValues={{ email: '', password: ''}}
                onSubmit={this.handleSubmit.bind(this)}
                validationSchema={signInSchema}
              >
                {({ values }) => (
                  <Form style={{ margin: '0px 10px'}}>
                    <FormikField label="Email" required name="email"
                      component={FormInput} size='large'>
                    </FormikField>
                    <ErrorMessage name="email" component={FormError} />
                    <FormikField label="Password" required name="password" type="password"
                      component={FormInput} size='large'>
                    </FormikField>
                    <ErrorMessage name="password" component={FormError} />
                    <Button appearance='primary' type='submit' size='large' style={{width: '100%', marginTop: 20}} >
                      Sign In
                    </Button>
                  </Form>
                )} 
              </Formik>
              <div style={{ display: 'flex', justifyContent:'end', marginRight:10, alignItems: 'center'}}>
                Don't have an account?<Button appearance='transparent' size='medium' onClick={() => this.props.navigate('/sign-up')}>{" "}Sign Up</Button>
              </div>
            </Card>
          </div>
        </div>
        {this.state.loader && <Loader/>}
        {/* <CustomModal open={this.state.open} width="20%"
          children={<ConfirmOTP 
            handleClose={this.handleClose.bind(this)}
            validateOtp={this.validateOtp.bind(this)}
            otp={this.state.otp}
            setOtp={(value) => {this.setState({ otp: value})}}
          />}
        /> */}
      </>
    );
  }
} 

const mapStateToProps = (state: any) => {
   return { userData: state.user };
}

const mapDispatchToProps = { setFirstName, setLastName, setUserId, setIndustry, setFunctionalArea, setRole,
  setEmail, setAccessToken, setRefreshToken, setIsAuthenticated };

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(SignIn));