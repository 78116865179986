import React from "react";


import { connect } from "react-redux";
import { withNavigation } from "../common/hooks/WithNavigation";
import { setCurrentLink } from "../../store/slices/userSlices";
import { Button, Card, CardHeader, Field, Input, MessageBar, MessageBarBody, MessageBarGroup, MessageBarTitle, Persona, Textarea } from "@fluentui/react-components";
import { RootState } from "../../store";
import { endpoints } from "../../store/apis/endpoints";
import axiosApi from "../../store/apis/axiosApi";
import { toast } from "react-toastify";
import Select from "react-select";
import InlineLoader from "../common/components/InlineLoader";
// const intents: MessageBarIntent[] = ["info", "warning", "error", "success"];
interface ContextualizationSettingsState {
  context: string;
  industry: { value: string, label: string} | null;
  industryList: Array<any>;
  role: { value: string, label: string} | null;
  roleList: Array<any>;
  domain: { value: string, label: string} | null;
  domailList: Array<any>;
  scope: string;
  conditions: any[];
  loader: boolean;
}

class ContextualizationSettings extends React.Component<any, ContextualizationSettingsState>{
  constructor(props: any) {
    super(props);
    this.state = {
      industry: null,
      industryList: [],
      roleList: [],
      domailList: [],
      role: null,
      domain: null,
      scope: '',
      context: '',
      loader: true,
      conditions: [
        { intent: "error", id: "1", title: 'Data Privacy:', context: 'Mask all contact informations' },
        { intent: "error", id: "2", title: 'Data Privacy:', context: "Mask all finacial/bank account numbers" },
        { intent: "error", id: "3", title: 'Data Privacy:', context: "Mask all credit/debit card numbers" },
        { intent: "warning", id: "4", title: 'Content:', context: "The number of characters in each response should not be more than 250" },
      ]
    }
  }

  componentDidMount(): void {
    this.getIndustryData();
    this.getFunctionalAreaData();
    this.getRoleData();
  }

  getIndustryData() {
    let payload = {
      url: `${endpoints.accounts.industry}?length=100`,
      method: 'GET'
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        this.setState({ industryList: res.body.data.results.map((v: any) => { return {value: v.id, label: v.name}})
        });
      } else {
        this.setState({ loader: false});
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  getFunctionalAreaData() {
    let payload = {
      url: `${endpoints.accounts.functionalArea}?length=100`,
      method: 'GET'
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        this.setState({ domailList: res.body.data.results.map((v: any) => { return {value: v.id, label: v.name}})
        });
      } else {
        this.setState({ loader: false});
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  getRoleData() {
    let payload = {
      url: `${endpoints.accounts.role}?length=100`,
      method: 'GET'
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        this.setState({ roleList: res.body.data.results.map((v: any) => { return {value: v.id, label: v.name}}),
          loader: false
        });
      } else {
        this.setState({ loader: false});
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  handleChange = (e: any)=>{
    this.setState({scope: e.target.value})
  }
  generateContext() {
    const { industry, role, domain, scope} = this.state;
    const { userData } = this.props;
    let payload = {
      url: endpoints.accounts.prePrompt,
      method: 'POST',
      headers: { Authorization: 'Bearer ' + userData.accessToken },
      body: { 
        industry: industry?.label,
        role: role?.label,
        domain: domain?.label,
        scope: scope
      }
    }
    axiosApi(payload).then(res => {
      if (res.isSuccess) {
        let prePrompt = res.body.data.prompt;
        this.setState({ context: prePrompt });
        this.props.setContext(prePrompt);
      } else {
        toast.error("Error Occurred: " + res.body.data ? res.body.data instanceof String ? res.body.data : res.body.data.message : res.body);
      }
    });
  }

  render() {
    let contextualizationSettingsContainer = {
      width: '100%',
      height: '100%',
      display: 'flex',
      overflow: 'auto '
    }
    let formContainer = {
      flexDirection: 'column' as 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      flexWrap: 'wrap' as 'wrap',
      padding: '20x',
      width: '100%',
      height: '100%'
    }
    let inputTextField = {
      display: "flex",
      flexDirection: "column" as "column",
      width: '285px',
      margin: '10px'
    }
    let inputTextField2 = {
      display: "flex",
      flexDirection: "column" as "column",
      width: 'calc(100% - 20px)',
      color: 'black',
      height: '200px',
      border: '1px solid',
      padding: '5px'
    }
    
    return (
      <div style={contextualizationSettingsContainer}>
        {this.state.loader ? <InlineLoader /> : 
        <div style={formContainer}>
        <Card style={{width: "calc(100% - 10px)", height: 'calc(100% - 10px)', margin: '5px', overflow: 'auto'}}>
        <CardHeader
            header={
              <Persona
                size="extra-large" 
                name={this.props.userData.product.title}
                tertiaryText={'"Please provide below details for me to generate your prompt!"'}
                avatar={{
                  image: {
                    src: this.props.userData.product.image
                }}}
              />  
            }
          />
          <MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
            <MessageBar intent="warning" layout="multiline">
              <MessageBarBody>
                <MessageBarTitle>{this.props.userData.product.title.substring(0, this.props.userData.product.title.indexOf(","))}: </MessageBarTitle>
                "The criteria for prompt engineering from input fields such as industry, role, domain, and purpose are currently experimental. This process involves the consistent training of language models and transformer agents to enhance their accuracy and effectiveness. As these models continue to evolve through iterative training and fine-tuning, their ability to generate precise and relevant contextual summaries will improve. Continuous feedback and updates are integral to refining these contextualization capabilities to better serve specific use cases and domains."
              </MessageBarBody>
            </MessageBar>
          </MessageBarGroup>

        <div className="Wraper" style={{ width: '100%', display: 'flex', height: '100%', overflow: 'auto' }}>
            <div style={{ width: '30%' }}>
              <div style={inputTextField}>
                <Field label="Industry">
                  <Select
                    name="industry"
                    value={this.state.industry}
                    placeholder="Select Industry"
                    isMulti={false}
                    options={this.state.industryList}
                    onChange={(e: any) =>  this.setState({ industry: e})}
                    styles={{
                      menuPortal: (styles) => ({ ...styles, height: 150 }),
                      menuList: (styles) => ({ ...styles, height: 150 }),
                      menu: (styles) => ({ ...styles, height: 150 })
                    }}
                  />
                </Field>
              </div>
              <div style={inputTextField}>
              <Field label="Domain">
                  <Select
                    name="domain"
                    value={this.state.domain}
                    placeholder="Select Domain"
                    isMulti={false}
                    options={this.state.domailList}
                    onChange={(e: any) =>  this.setState({ domain: e})}
                    styles={{
                      menuPortal: (styles) => ({ ...styles, height: 150 }),
                      menuList: (styles) => ({ ...styles, height: 150 }),
                      menu: (styles) => ({ ...styles, height: 150 })
                    }}
                  />
                </Field>
              </div>
              <div style={inputTextField}>
                <Field label="Role">
                    <Select
                      name="role"
                      value={this.state.role}
                      placeholder="Select Role"
                      isMulti={false}
                      options={this.state.roleList}
                      onChange={(e: any) =>  this.setState({ role: e})}
                      styles={{
                        menuPortal: (styles) => ({ ...styles, height: 150 }),
                        menuList: (styles) => ({ ...styles, height: 150 }),
                        menu: (styles) => ({ ...styles, height: 150 })
                      }}
                    />
                  </Field>
              </div>
              <div style={inputTextField}>
                <Field label="What do you want me to answer?">
                  <Input type="text" value={this.state.scope} onChange={this.handleChange.bind(this)}/>
                </Field>
              </div>
              <Button style={inputTextField} appearance="primary" onClick={this.generateContext.bind(this)}>
                Generate Prompt
              </Button>
            </div>
            <div style={{ width: '70%' }}>
              <Field label="Generated Prompt: ">
                <Textarea style={inputTextField2} onChange={(e: any) => {
                  this.setState({ context: e.target.value });
                  this.props.setContext(e.target.value);
                  }} 
                  value={this.state.context}
                />
              </Field>
            </div>
          </div>

          {/* <div style={{ width: '100%' }}>
            <Divider alignContent={'start'}><h6 style={subtitleText}>Conditions & Limitations</h6></Divider>
            <MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
            <MessageBar intent="warning" layout="multiline">
              <MessageBarBody>
                <MessageBarTitle>Note</MessageBarTitle>
                The following section is experimental and currently under research. It is subject to change or removal in the future. The input provided in this section will help us improve the functionality and accuracy of our systems moving forward. Your participation and feedback are greatly appreciated as we work to enhance our capabilities.
              </MessageBarBody>
            </MessageBar>
          </MessageBarGroup>
          <div style={controlsContainer}>
              <div style={buttonGroup}>
                <Button disabled appearance="primary" onClick={() => {
                  const newCondition = { intent: "warning", id: "messgeid", title: 'warning'.toLocaleUpperCase(), context: '' };
                  this.state.conditions.push(newCondition);
                  this.setState({ conditions: this.state.conditions });
                  
                }}>
                  Add a Condition
                </Button>
                <Button disabled onClick={() => this.setState({ conditions: [] })}>Clear</Button>
              </div>
            </div>
            <MessageBarGroup animate={'both' as 'both'} style={messageBarGroup}>
              {this.state.conditions.map(({ intent, id, title, context }) => (
                <MessageBar key={`${intent}-${id}`} intent={intent}>
                  <MessageBarBody>
                    <MessageBarTitle>{title}</MessageBarTitle>
                    {context}
                  </MessageBarBody>
                  <MessageBarActions
                    containerAction={
                      <Button
                        onClick={() => this.setState({ conditions: this.state.conditions.filter((condition: any) => condition.id !== id) })}
                        aria-label="dismiss"
                        appearance="transparent"
                        icon={<Dismiss24Regular />}
                      />
                    }
                  />
                </MessageBar>
              ))}
            </MessageBarGroup>
            
          </div> */}
        </Card>
        </div>
        }
      </div >
    );
  }

}

const mapStateToProps = (state: RootState) => {
  return { llmData: state.largeLanguageModel, userData: state.user };
}
const mapDispatchToProps = { setCurrentLink };

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(ContextualizationSettings));