import { Body1, Caption1, Card, CardHeader, CardPreview } from "@fluentui/react-components";
import React from "react";
import { connect } from "react-redux";
import { setConversation } from "../../store/slices/conversationHistorySlices";
import { setSelectedFiles } from "../../store/slices/fileSlices";
import { RootState } from "../../store";
import logo1 from '../../assets/images/ServiceNow_logo.svg.png';
interface CollaboratorsProps {
}
interface CollaboratorsState {
  selectedFiles: any;
  collabList: Array<any>;
}
class Collaborators extends React.Component<CollaboratorsProps, CollaboratorsState> {
  constructor(props: CollaboratorsProps) {
    super(props);
    this.state = {
      selectedFiles: {},
      collabList: [{value: '1', label: 'SAP'},{ value: '2', label: 'ServiceNow'}]
    }
  }

  
  onSelectedChange = (data: any) => {
    this.setState({selectedFiles: data})
  }

  render() {
    return (
      <Card style={{ width: '100%' }}>
        <CardHeader
          header={
            <Body1 style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', width: '100%' }}>
              <Caption1>{this.state.collabList.length} System</Caption1>
            </Body1>
          }
          
        />
        
        <CardPreview style={{
          overflow: 'auto', display: 'flex'
        }}>
          <div>
            {this.state.collabList.map((file: any) => (
            <Card
              key={file.value.toString()}
              style={{
                height: "fit-content", display:'flex', flexDirection:'row', justifyContent: 'space-between',
                margin: "5px 15px", padding: 5,
              }}
              selected={file.value === this.state.selectedFiles?.value}
            >
              <CardHeader
                image={
                  <img
                  src={logo1}
                  alt="system"
                />
                }
              />
            </Card>
            ))}
          </div>
        </CardPreview>
      </Card >
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: state.user,
    conv: state.conversationHistory,
    file: state.files,
    llm: state.largeLanguageModel
   };
}
const mapDispatchToProps = { setConversation, setSelectedFiles };
export default connect(mapStateToProps, mapDispatchToProps)(Collaborators);
