export const endpoints = {
  accounts : {
    register : 'accounts/register/',
    login : 'accounts/login/',
    validate : 'accounts/validate-otp/',
    verify : 'accounts/verify-account/',
    product: 'accounts/product/',
    industry: 'accounts/industry/',
    functionalArea: 'accounts/functional-area/',
    role: 'accounts/role/',
    prePrompt: 'accounts/pre-prompt/'
  },
  advisor: {
    uploadFile: 'advisor/upload-files/',
    document: 'advisor/document/',
    conversation: '/advisor/conversation/',
    configuration: '/advisor/configuration/',
    chat: '/advisor/chat/',
    askQuestion: '/advisor/ask-quetion/',
    askQuestionMultiDoc: '/advisor/ask-quetion-multi-doc/',
    largelanguageModel: '/advisor/llm/',
    quote: '/quote/quote/'
  },
  scoreCard : {
    graph: 'score-card/graph/',
    table: 'score-card/parameter-api/',
    parameter: 'score-card/parameter/',
    score: 'score-card/'
  }
}