import { Button, Caption1, Card, CardHeader } from "@fluentui/react-components";
import React from "react";
import { Conversation, File, FileState, LLM } from "../../store/types";
import { RootState } from "../../store";
import { connect } from "react-redux";

interface ConversationProps {
  onChangeConv(): void;
  conversation: Conversation | null;
  file: FileState;
}
interface ConversationState {
  conversation: Conversation | null;
  selectedDoc: File[];
  llmList: Array<LLM>;
}
class CurrentConversation extends React.Component<ConversationProps | any, ConversationState> {
  constructor(props: ConversationProps | any) {
    super(props);
    this.state = {
      conversation: this.props.conversation,
      selectedDoc: [],
      llmList: []
    }
  }
  componentDidMount(): void {
    this.setData();
  }

  componentDidUpdate(prevProps: Readonly<ConversationProps>, prevState: Readonly<ConversationState>, snapshot?: any): void {
    if(this.props.conversation !== prevProps.conversation) {
      this.setState({ conversation: this.props.conversation});
      this.setData();
    }
  }

  setData() {
    const { conversation, file } = this.props;
    if(file.docList.results > 0) {
      let selectedDoc: Array<File> = file.docList.results.filter((v: any) => v.id === conversation?.document);
      this.setState({selectedDoc : selectedDoc});
    }
  }

  render(): React.ReactNode {
    return (
      <Card style={{ width: '100%'}}>
        <CardHeader
          header={
            <div style={{display:'flex', flexDirection:'row', width:'100%', gap: 5, 
              justifyContent: this.state.conversation === null ? 'center' : 'space-between'}}
            >
              {this.state.conversation && 
                <div style={{display:'flex', flexDirection:'column'}}>
                  <b>{this.state.conversation?.name}</b>
                  <Caption1>{this.state.selectedDoc[0]?.filename}{this.state.selectedDoc.length>1 && <>and {this.state.selectedDoc.length-1} more files.</>}</Caption1>
                </div>
              }
              <Button appearance="transparent" onClick={this.props.onChangeConv}>
                {this.state.conversation === null ? 'Select/Create Conversation' : 'Change'}
              </Button>
            </div>
          }
        />   
      </Card>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { file: state.files };
}
export default connect(mapStateToProps)(CurrentConversation);