import React from "react";
import LLMList from "./LLMList";
import axiosApi from "../../store/apis/axiosApi";
import { toast } from "react-toastify";
import { endpoints } from "../../store/apis/endpoints";
import { connect } from "react-redux";
import { setLLMList } from "../../store/slices/largeLanguageModelSlices";
import { AuthData, LLM, LLMPageData, LLMState } from "../../store/types";
import { RootState } from "../../store";
import ScoreCard from "../pages/components/ScoreCard";
import { SelectTabData, SelectTabEvent, Tab, TabList } from "@fluentui/react-tabs";
import { Card, CardHeader } from "@fluentui/react-card";
import ScoreTable from "../pages/components/ScoreTable";
import { Persona } from "@fluentui/react-components";

interface ExploreLLMProps {
  userData: AuthData;
  setLLMList(data: LLMPageData): void;
  llm: LLMState;
}
interface ExploreLLMState {
  evalData: any;
  selectedTab: string | unknown;
  isError: boolean;
}
class ExploreLLM extends React.Component<ExploreLLMProps, ExploreLLMState>{
  constructor(props: ExploreLLMProps) {
    super(props);
    this.state = {
      evalData: null,
      selectedTab: 'GLOBAL',
      isError: false
    }
  }
  componentDidMount(): void {
    this.getLLMList();
  }

  setShowEval(data: LLM) {
    this.setState({ evalData: data });
  }
  getLLMList() {
    const { userData, setLLMList } = this.props;
    let payload = {
      url: endpoints.advisor.largelanguageModel,
      method: 'GET',
      headers: { Authorization: 'Bearer ' + userData.accessToken }
    }
    axiosApi(payload).then(res => {
      if (res.isSuccess) {
        setLLMList(res.body.data)
      } else {
        toast.error(res.body.data ? res.body.data.message : res.body);
        this.setState({ isError: true })
      }
    });
  }
  onTabSelect(event: SelectTabEvent, data: SelectTabData) {
    this.setState({ selectedTab: data.value });
  };
  render() {
    return (
      <div className="Wraper" style={{ padding: 8, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%', overflow: 'auto' }}>
        <LLMList setShowEval={this.setShowEval.bind(this)} llmList={this.props.llm.llmList} isError={this.state.isError}></LLMList>
        <div style={{ margin: '5px', width: '100%', height: '100%' }}>
          <Card style={{width: '100%', height: '100%'}}>
            <CardHeader
              header={
                <Persona
                  size="extra-large" 
                  name={this.props.userData.product.title}
                  tertiaryText={'"You can view and compare scores of LLM(s) here!"'}
                  avatar={{
                    image: {
                      src: this.props.userData.product.image
                  }}}
                />  
              }
            />
            <TabList selectedValue={this.state.selectedTab} onTabSelect={this.onTabSelect.bind(this)}>
              <Tab value="GLOBAL">Global Score</Tab>
              <Tab value="PRODUCT">User Score</Tab>
            </TabList>
            <div style={{width: '100%', height: '100%', overflowY: 'auto'}}>
              <ScoreTable type={this.state.selectedTab}></ScoreTable>
              <ScoreCard type={this.state.selectedTab}></ScoreCard>
            </div>
          </Card>

        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { llm: state.largeLanguageModel, userData: state.user };
}
const mapDispatchToProps = { setLLMList };
export default connect(mapStateToProps, mapDispatchToProps)(ExploreLLM);