import React from "react";
import DataList from "./DataList";
import { endpoints } from "../../store/apis/endpoints";
import axiosApi from "../../store/apis/axiosApi";
import { toast } from "react-toastify";
import { setDocList } from "../../store/slices/fileSlices";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { AuthData, FilePageData, LLM } from "../../store/types";
import DataInputs from "./DataInputs";
import Loader from "../common/components/Loader";

interface IngestYourDataProps {
  userData: AuthData;
  setDocList(data: FilePageData): void;
}

interface IngestYourDataState {
  inputSource: string;
  selectedInput: { id: number, name: string, mimeType: string, dataUrl: string, dataSource: string };
  evalData: LLM | null;
  docPageData: FilePageData;
  loader: boolean;
  isError: boolean;
}
class IngestYourData extends React.Component<IngestYourDataProps,IngestYourDataState>{
  constructor(props: IngestYourDataProps) {
    super(props);
    this.state = {
      inputSource: "upload",
      selectedInput: { id: 1, name: "Salse call @ "+new Date(), mimeType: "audio/mp4", dataUrl: "https://graph.microsoft.com/calls?meetingId=eshshegydaaa-ss--ff", dataSource: "Microsoft Teams" },
      evalData: null,
      loader: true,
      docPageData: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      isError: false
    }
  }

  setShowEval(data: LLM) {
    this.setState({evalData: data});
  }

  componentDidMount(): void {
    this.getDocList(0);
  }

  getDocList(page: number | null) {
    const { userData } = this.props;
    let payload = {
      url: `${endpoints.advisor.document}?${page === 0 ? '' : '&page='+page}`,
      method: 'GET',
      headers: { Authorization : 'Bearer ' + userData.accessToken }
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        this.setState({ docPageData : res.body.data, loader: false})
      } else {
        toast.error(res.body.data ? res.body.data.message : res.body);
        this.setState({ loader: false, isError: true})
      }
    });
  }

  render() {
    return (
      <> {this.state.loader && <Loader />}
        <div className="Wraper" style={{display: 'flex', flexDirection: 'row',
          justifyContent:'space-between', height: '100%', width: '100%x',overflow: 'auto'}}>
          <DataInputs getDocList={this.getDocList.bind(this)}></DataInputs>
          {this.state.docPageData.results.length>0 && (<DataList getDocList={this.getDocList.bind(this)} docPageData={this.state.docPageData} isError={this.state.isError}></DataList>)}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { file: state.files, userData: state.user };
}
const mapDispatchToProps = { setDocList };
export default connect(mapStateToProps, mapDispatchToProps)(IngestYourData);
