import React from "react";
import { Card, CardHeader, CardPreview, MessageBar, MessageBarBody, MessageBarGroup, Persona, Spinner } from "@fluentui/react-components";
import { connect } from "react-redux";
import { setDocList, setSelectedFiles } from "../../store/slices/fileSlices";
import { RootState } from "../../store";
import { AuthData, File, FilePageData, FileState } from "../../store/types";
import DataCard from "./DataCard";
import InfiniteScroll from 'react-infinite-scroll-component';
import InlineLoader from "../common/components/InlineLoader";

interface DataListProps {
  userData: AuthData;
  file: FileState;
  setDocList(data: Array<File>): void;
  setSelectedFiles(data: File[]): void;
  getDocList(page: number | null, offset: number): void;
  docPageData: FilePageData;
  isError:  boolean;
}
interface DataListState {
  selected: File[];
  loader: boolean;
  docPageData: FilePageData;
  docList: Set<File>;
}
class DataList extends React.Component<DataListProps, DataListState>{
  constructor(props: DataListProps) {
    super(props);
    this.state = {
      selected: this.props.file.selectedFiles,
      loader: true,
      docPageData: this.props.docPageData,
      docList: new Set(this.props.docPageData.results)
    }
  }

  componentDidMount(): void {
    if (Array.from(this.state.docList).length > 0) {
      this.setState({ loader: false });
    }
  }

  componentDidUpdate(prevProps: Readonly<DataListProps>, prevState: Readonly<DataListState>, snapshot?: any): void {
    if (prevProps.docPageData.results !== this.props.docPageData.results) {
      let items = prevProps.docPageData.next !== this.props.docPageData.next ?
        this.props.docPageData.previous === null ? new Set([...this.props.docPageData.results]) :
          new Set([...Array.from(this.state.docList), ...this.props.docPageData.results]) :
        new Set([...this.props.docPageData.results])
      this.setState({
        docPageData: this.props.docPageData,
        docList: items, loader: false
      })
      this.props.setDocList(Array.from(items));
    }
  }

  updateState(i: File) {
    if (this.state.selected instanceof Array) {
      if(this.props.userData.product.slug === "theadvisor") {
        var index = this.state.selected.indexOf(i);
        if (index!==-1) {
          this.setState({ selected: this.state.selected.filter(file=>file.id!==i.id) }, 
            () => this.props.setSelectedFiles(this.state.selected));
        } else {
          this.setState({ selected: this.state.selected.concat(i)}, 
            () => this.props.setSelectedFiles(this.state.selected));
        }
      }
      else {
        var selectedFile = new Array(i);
        this.setState({ selected: selectedFile });
        this.props.setSelectedFiles(selectedFile)
      }     
    }
  }

  render() {
    return (

      <div style={{
        display: 'flex', flexDirection: 'column', width: '100%', margin: 5
      }}>
        <Card style={{
          width: "100%", height: '100%',
          display: 'flex', justifyContent: 'start',
          flexDirection: 'column'
        }}>
          <CardHeader style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'flex-start', justifyContent: 'space-between' }}
            header={
              <Persona
                size="extra-large"
                name={this.props.userData.product.title}
                tertiaryText={'"You can also choose from your recently-uploaded files!"'}
                avatar={{
                  image: {
                    src: this.props.userData.product.image
                  }
                }}
              />
            }
          />

          <CardPreview as="div" style={{
            overflowY: 'auto', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 20px 10px inset',
            border: '1px solid #888', height: '100%'
          }}
          >
            {this.state.loader ? <InlineLoader/> : 
              this.props.isError ? 
              <MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
                <MessageBar intent="error" layout="multiline">
                  <MessageBarBody>
                    There seems to be an issue, Please try after sometime.
                  </MessageBarBody>
                </MessageBar>
              </MessageBarGroup> :
            <InfiniteScroll
              dataLength={this.state.docPageData.next === null ? 5 : (this.state.docPageData.next - 1) * 5}
              hasMore={this.state.docPageData.next !== null}
              loader={<div><Spinner size='small' style={{height: 'inherit'}}/></div>}
              next={() => {
                this.props.getDocList(this.state.docPageData.next, 5)
              }}
              height={'100%'}
            >
              {Array.from(this.state.docList).map((data: File) => (
                <DataCard key={data.id} data={data}
                  selected={this.state.selected} onSelect={this.updateState.bind(this)} />
              ))}
            </InfiniteScroll>
            }
          </CardPreview>
        </Card>

      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { file: state.files, userData: state.user };
}
const mapDispatchToProps = { setDocList, setSelectedFiles };

export default connect(mapStateToProps, mapDispatchToProps)(DataList);