import { Rating } from '@fluentui/react-rating';
import React, { useState } from 'react';
import Moment from 'react-moment';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store';
import { ChatMessage, Parameters } from '../../store/types';
import { endpoints } from '../../store/apis/endpoints';
import axiosApi from '../../store/apis/axiosApi';
import { toast } from 'react-toastify';
interface MessageWithToolbarProps {
  message: ChatMessage;
  llmId: string;
}
const MessageWithToolbar = ({ message , llmId }: MessageWithToolbarProps) => {
  const parameters = useAppSelector((state: RootState) => state.largeLanguageModel.parameters);
  const userData = useAppSelector((state: RootState) =>  state.user);
  const [ chatScore, setChatScore ] = useState<Array<any>>(message.scoreCardScorecardChat);

  const updateRating = (value: number, paramId: string, message: ChatMessage) => {
    console.log(chatScore);
    let scoreId = null;
    const scoreCardData = chatScore.filter(value => value.parameter === paramId);
    if(scoreCardData.length > 0) 
      scoreId =  scoreCardData[0].id;
    updateCustomerScore(value, message.messageId, llmId, paramId, scoreId);
  }

  const updateCustomerScore = (value: number, chat_id: string, llmId: string, paramId: string, scoreId: string| null) => {
    let payload = {
      url: endpoints.scoreCard.score + (scoreId === null ? '' : scoreId + '/'),
      method: scoreId === null ? 'POST' : 'PATCH',
      headers: { Authorization : 'Bearer ' + userData.accessToken },
      body: scoreId === null ? {
        rank: value,
        chat: chat_id,
        llm: llmId,
        parameter: paramId
      } : {
        id: scoreId,
        rank: value,
        chat: chat_id,
        llm: llmId,
        parameter: paramId
      }
    }
    axiosApi(payload).then(res => {
      if(!res.isSuccess) {
        toast.error(res.body.data ? res.body.data.message : res.body);
      } else {
        if(chatScore.length <= 0) {
          let score = res.body.data;
          let temp = [];
          temp.push(score);
          setChatScore(temp);
        } else {
          const data = chatScore.filter(value => value.parameter === paramId);
          if(data.length > 0) {
            let updatedScore = chatScore.map(v => {
              if(v.parameter === paramId) {
                return { ...v , rank: value }
              }
              return v;
            })
            setChatScore(updatedScore);
          } else  {
            let score = res.body.data;
            let temp = [ ...chatScore];
            temp.push(score);
            setChatScore(temp);
          }
        }
      }
    });
  }
  const [isHovered, setIsHovered] = useState(false);
  const chatMessageContainer = {
    fontStyle: 'italic',
    boxShadow: '0px 3.2px 7.2px rgb(0 0 0 / 13\u0025), 0px 0.6px 1.8px rgb(0 0 0 / 11\u0025)',
    padding: '10px', border: '1px solid #ccc', borderRadius: '5px', marginBottom: '10px'
  }
  const myChatMessageContainer = {
    fontStyle: 'italic',
    backgroundColor: '#c7e0f4',
    boxShadow: '0px 3.2px 7.2px rgb(0 0 0 / 13\u0025), 0px 0.6px 1.8px rgb(0 0 0 / 11\u0025)',
    padding: '10px', border: '1px solid #ccc', borderRadius: '5px', marginBottom: '10px'
  }

  const getRatingData = (paramId: string) => {
    const scoreCardScorecardChat = chatScore;
    const scoreCardData = scoreCardScorecardChat.filter(value => value.parameter === paramId);
    if(scoreCardData.length <= 0) 
      return 0;
    else return scoreCardData[0].rank;
  }
  
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={message.mine ? myChatMessageContainer : chatMessageContainer}
    >
      <div>{!message.mine && <span style={{ marginRight: '10px' }}>{message.senderDisplayName}</span>}<span><Moment format="YYYY-MM-DD HH:mm">{message.createdOn}</Moment></span></div>
      {message.content === '' && <div style={{ padding: '10px 20px' }}><div className='dot-flashing'></div></div>}
      {message.content !== '' && <div style={{ padding: '10px 20px' }}>{message.content}</div>}
      {isHovered && !message.mine && (
        <div>
          <h5>How do you rate my answer?</h5>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', background: 'light-blue', borderRadius: '5px', padding: '5px', marginBottom: '10px' }}>
            {parameters.length > 0 && parameters.map((param: Parameters) => (
              <div style={{ margin: '5px' }} key={param.id}>
                <label htmlFor="">{param.name}</label>
                <Rating color="marigold" size="small" defaultValue={getRatingData(param.id)} 
                  onChange={(e: any) => updateRating(e.target.value, param.id, message)}
                />
              </div>
            ))}
          </div>
        </div>
        )}
    </div>
  );
};

export default MessageWithToolbar;