import React from "react";

class NoMatchFound extends React.Component{
  
  render() {
    return (
      <>
          404-Page Not Found
      </>
    );
  }
}
export default NoMatchFound;
