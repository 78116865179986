import { useLocation, useNavigate } from "react-router-dom";

export const withNavigation = (WrappedComponent: any) => (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      location={location}
    />
  );
};