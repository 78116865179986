import { Button, Card, CardFooter, CardHeader, CardPreview, Persona } from "@fluentui/react-components";
import React from "react";
import { connect } from "react-redux";
import axiosApi from "../../store/apis/axiosApi";
import { endpoints } from "../../store/apis/endpoints";
import { toast } from "react-toastify";
import { resetUploadadFiles } from "../../store/slices/fileSlices";
import { AuthData, FileState } from "../../store/types";
import { RootState } from "../../store";
import Loader from "../common/components/Loader";
import UploadFile from "./UploadFile";
const FormData = require('form-data');

interface UploadFileProps {
  file: FileState;
  resetUploadadFiles(): void;
  getDocList(page : number): void;
  userData: AuthData;
}
interface UploadFileState {
  inputTypes: any[];
  loader: boolean;
  productId: string | null;
}

class DataInputs extends React.Component<UploadFileProps, UploadFileState>{
  constructor(props: UploadFileProps) {
    super(props);
    this.state = {
      inputTypes: ['Upload Files', 'Load from communicator', 'Load from cloud storage'],
      loader : false,
      productId: ''
    };
  }

  componentDidMount(): void {
    let productId = localStorage.getItem('productId');
    this.setState({ productId: productId});
  }

  uploadFiles() {
    this.setState({ loader: true});
    const { uploadedFiles } = this.props.file;
    const formData = new FormData();
    uploadedFiles.forEach((element: any) => {
      formData.append('files', element)
    });
    let payload = {
      url: endpoints.advisor.uploadFile,
      method: 'POST',
      headers: { 
        Authorization : 'Bearer ' + this.props.userData.accessToken,
      },
      body: formData
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        this.setState({ loader: false});
        toast.success('Files Uploaded Successfully');
        this.props.resetUploadadFiles();
        this.props.getDocList(0);
      } else {
        this.setState({ loader: false});
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  render() {
    
    return (
      <>{this.state.loader && <Loader/>}
        <Card style={{width: "100%", height: '100%', margin: '5px'}}>
          <CardHeader
            header={
              <Persona
                size="extra-large" 
                name={this.props.userData.product.title}
                tertiaryText={this.state.productId==='theadvisor' ? 
                  '"Which document should I examine? I currently work with.pdf, but I\'ll soon be reviewing additional file formats as well."' : 
                    this.state.productId==='theanalyser' ?
                '"Which data should I examine? I currently work with.xlsx and.csv files, but I\'ll soon be analyzing additional formats as well."' : 
                '"Which conversion should I translate? I currently work with.mp3 files, but I\'ll soon be translating additionnal audio formats as well."'}
                avatar={{
                  image: {
                    src: this.props.userData.product.image
                }}}
              />  
            }
          />

          <CardPreview style={{height: '70%'}}>
            <UploadFile></UploadFile>
          </CardPreview>

          <CardFooter>
            <Button onClick={this.uploadFiles.bind(this)}
              disabled={this.props.file.uploadedFiles.length < 1}
            >
              Upload
            </Button>
          </CardFooter>
        </Card>
        
        </>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { file: state.files, userData: state.user };
}
const mapDispatchToProps = { resetUploadadFiles };
export default connect(mapStateToProps,mapDispatchToProps)(DataInputs);