import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { initializeIcons, registerIcons } from '@fluentui/react';
import { DEFAULT_COMPONENT_ICONS } from '@azure/communication-react';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './pages/common/components/Loader';

initializeIcons();

const customIcons = {...DEFAULT_COMPONENT_ICONS};
registerIcons({ icons: customIcons });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    
      <Provider store={store}>
        <PersistGate loading={<Loader/>} persistor={persistor}>
          <FluentProvider theme={teamsLightTheme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          </FluentProvider>
        </PersistGate>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
