const CustomDrawer = ({open, children, position, width}: any) => {

  let drawerStyle: any = { position: 'fixed', left: `calc(50% - ${width/2}px)`, width: width, zIndex: 5, 
  transition:'transform 0.5s', transform: open ? 'scale(1)' : 'scale(0)',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 10px 5px'}
  if(position === "top")
    drawerStyle.top = 0;
  else drawerStyle.bottom = 0;

  return (
    <>
      <div style={drawerStyle}
			>
				{children}
      </div>
    </>
    );
}
export default CustomDrawer;