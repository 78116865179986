import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import userReducer from './slices/userSlices';
import fileReducer from './slices/fileSlices';
import conversationHistoriesReducer from './slices/conversationHistorySlices';
import largeLanguageModelsReducer from './slices/largeLanguageModelSlices';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';


const rootReducer = combineReducers({
  user: userReducer,
  files : fileReducer,
  conversationHistory: conversationHistoriesReducer,
  largeLanguageModel: largeLanguageModelsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware : (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export const persistor = persistStore(store)

// Infer the type of `store`
export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch);