import { Persona } from "@fluentui/react-components";
import React from "react";
import { MessageContentType, MessageStatus, MessageThread } from "@azure/communication-react";
import { AuthData, ChatMessage, LLMState } from "../../store/types";
import { RootState } from "../../store";
import { connect } from "react-redux";
import MessageWithToolbar from "./MessageWithToolBar";
import { setScoreCardLLM } from "../../store/slices/largeLanguageModelSlices";

interface ChatWindowProps {
  messages: Array<any>;
  userData: AuthData;
  llmId: string;
  llmName: string;
  llm: LLMState;
  setScoreCardLLM(data: Array<string>): void;
}
interface ChatWindowState {
  messages: Array<any>;
  llm : string;
  selectedLLM: Array<string>;
}
class ChatWindow extends React.Component<ChatWindowProps | any, ChatWindowState> {
  constructor(props: ChatWindowProps | any) {
    super(props);
    this.state = {
      messages: this.setChatMessages(this.props.messages),
      llm: this.props.llmName,
      selectedLLM: []
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<ChatWindowState>, snapshot?: any): void {
    if(prevProps.messages !== this.props.messages) {
      this.setState({ 
        messages: this.setChatMessages(this.props.messages),
        llm: this.props.llmName
      })
    }
  }

  setChatMessages(obj: Array<any>): Array<ChatMessage> {
    if(obj !== undefined && obj.length > 0) {
      return obj.map((value) => {
        return {
          messageType: value.messageType,
          contentType: value.contentType === 'html' ? 'html' : 'text' as MessageContentType,
          senderId: value.mine ? value.senderId : '2',
          senderDisplayName: value.mine ? 'Me' : this.props.userData.product.title.substring(0, this.props.userData.product.title.indexOf(",")),
          messageId: value.id,
          content: value.content,
          createdOn: value.createdAt !== undefined ? new Date(value.createdAt) : value.createdOn,
          mine: value.mine,
          attached: false,
          status: 'seen' as MessageStatus,
          scoreCardScorecardChat: value.scoreCardScorecardChat
        }
      })
    } else {
      return [];
    }
  }

  updateState(e : any) {
    const { llm, llmId, setScoreCardLLM } = this.props;
    let items = [...llm.scoreCardLLMs];
    if(e.target.checked) {
      items.push(llmId);
    } else {
      items = items.filter(v => v !== llmId);
    }
    setScoreCardLLM(items);
  }

  render() {
    const renderMessage = (messageProps: any) => <MessageWithToolbar message={messageProps.message} llmId={this.props.llmId}/>;
    const renderAvatar = (messageProps: any) => <img
    src={this.props.userData.product.image}
    alt="User Avatar"
    style={{ width: '32px', height: '32px', borderRadius: '50%' }}
  />;

    const messageThreadStyle ={
      chatMessageContainer: {
        fontStyle: 'italic',
        boxShadow: '0px 3.2px 7.2px rgb(0 0 0 / 13\u0025), 0px 0.6px 1.8px rgb(0 0 0 / 11\u0025)'
      },
      myChatMessageContainer: {
        fontStyle: 'italic',
        boxShadow: '0px 3.2px 7.2px rgb(0 0 0 / 13\u0025), 0px 0.6px 1.8px rgb(0 0 0 / 11\u0025)'
      },
      systemMessageContainer: { fontWeight: 'bold', border: 'double red' }
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', overflow: 'auto'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: '10px 15px',
          borderBottom: '1px solid #cccccc'}}>
          <Persona
            className="react-grid-dragHandleExample draggable"
            style={{ width : '100%', cursor: 'grab'}}
            size="medium"
            name={this.state.llm}
            secondaryText="Available"
            presence={{ status: "available" }}
            {...this.props}
          />
          {/* <Tooltip content='Clear Chat' relationship="label">
            <Button style={{marginLeft: 5}} icon={<ChatDismiss20Regular />}
              onClick={() => {}}
            />
          </Tooltip> */}
          {/* <Checkbox onChange={this.updateState.bind(this)}/> */}
        </div>
        <div style={{ display: 'flex', padding: '10px 15px', overflow: 'auto'}}>
        {this.state.messages && this.state.messages.length > 0 ?
          <MessageThread userId={this.props.userData.userId} styles={messageThreadStyle} messages={this.state.messages} showMessageDate={true} disableEditing={true} showMessageStatus={true}  onRenderMessage={renderMessage} onRenderAvatar={renderAvatar}/>
          : <></>
        }
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { userData: state.user, llm: state.largeLanguageModel };
}
const mapDispatchToProps = { setScoreCardLLM };
export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
