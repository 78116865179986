import React from "react";
import Dropzone, { Accept } from "react-dropzone";
import { connect } from "react-redux";
import { setUploadedFiles } from "../../store/slices/fileSlices";
import { RootState } from "../../store";
import { FileState } from "../../store/types";
import { Text } from "@fluentui/react-components";
import { CloudAddRegular } from "@fluentui/react-icons";

interface UploadFileProps {
  file: FileState;
  setUploadedFiles(data: Array<any>): void;
}
interface UploadFileState {
  files: any[]
}

class UploadFile extends React.Component<UploadFileProps, UploadFileState>{
  onDrop: (files: any) => void;
  constructor(props: UploadFileProps) {
    super(props);
    this.onDrop = (files) => {
      this.setState({ files: files });
      this.props.setUploadedFiles(files);
    };
    this.state = {
      files: this.props.file.uploadedFiles
    };
  }
  getAcceptedfiles(): Accept {
    let productId = localStorage.getItem('productId');
    if (productId === 'theadvisor') {
      return {
        'application/pdf': ['.pdf']
      }
    } else if (productId === 'theanalyser') {
      return {
        // 'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'text/csv': ['.csv']
      }
    } else if (productId === 'thetranslator') {
      return {
        'audio/mpeg': ['.mp3'],
        // 'audio/mp4': ['.mp4'],
        // 'audio/wav': ['.wav']
      }
    } else {
      return {
        'application/pdf': ['.pdf'],
        'application/csv': ['.csv']
      }
    }
  }

  render() {
    const style_container = {
      color: "black",
      backgroundColor: "white",
      padding: "20px 60px",
      fontFamily: "Arial",
      border: "1px solid #a1a1a1",
      borderRadius: "3px",
      width: "90%",
      height: "60%"
    }
    const style_zone = {
      fontFamily: "Arial",
      borderWidth: "2px",
      borderRadius: "2px",
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      height: '100%',
      textAlign: 'center' as 'center',
      display: 'flex',
      flexDirection: 'row' as 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }

    const files_zone = {
      fontFamily: "Arial",
      backgroundColor: "white",
      color: "#777777",
      overflow: "auto",
      margin: 0,
      width: 'calc(100% - 10px)',
      listStyleType: "none",
      padding: '10px',
    }
    let productId = localStorage.getItem('productId');
    const files = this.state.files.map(file => (
      <li key={file.path}>
        {file.path} - {(file.size/(1024*1024)).toFixed(2)} MB
      </li>
    ));
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
        <Dropzone
          multiple={productId === 'theadvisor'}
          accept={this.getAcceptedfiles()} onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (<>
            <section style={style_container}>
              <div {...getRootProps()} style={style_zone}>
                <input {...getInputProps()} />
                <CloudAddRegular style={{ fontSize: 48, marginBottom: 10 }} />
                <Text size={500} weight="semibold">Drag and Drop or click here to browse files </Text>
              </div>
            </section>
            <h4 style={{margin:'10px'}}>Selected Files</h4>
            <div style={files_zone}>
              <aside>
                <ul>{files}</ul>
              </aside>
            </div></>
          )}
        </Dropzone>
      </div >
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { file: state.files };
}
const mapDispatchToProps = { setUploadedFiles };

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
