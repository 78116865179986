import React from 'react';
import './App.css';
import { ToastContainer, Zoom } from 'react-toastify';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import AppBarHead from './pages/common/components/AppBarHead';
import SignUp from './pages/authendication/SignUp';
import SignIn from './pages/authendication/SignIn';
import NoMatchFound from './pages/common/components/NoMatchFound';
import Home from './pages/pages/components/Home';
import QuoteForm from './pages/pages/components/QuoteForm';
import Workspace from './pages/workspace/Workspace';
import ScoreCard from './pages/pages/components/ScoreCard';
import EasyStart from './pages/easyStart/EasyStart';
import { Tooltip } from 'react-tooltip';

class App extends React.Component {
  
  render() {
  return (
    <>
        <ToastContainer 
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Zoom}
          style={{zIndex: 100}}
        />
    <div className="App">
      <header className="App-header">
        <AppBarHead ></AppBarHead>
      </header>
      <section className="main">
      <Routes>
                  <Route index element={<Navigate to='/home' replace/>} />
                  <Route path="sign-up" element={<SignUp />} />
                  <Route path="sign-in" element={<SignIn />} />
                  <Route path="home" element={<Home />} />
                  <Route path="easy-to-start" element={<EasyStart/>} />
                  <Route path="request-for-quote" element={<QuoteForm />} />
                  <Route path="my-workspace" element={<Workspace />} />
                  <Route path="score-cards" element={<ScoreCard />} />
                  <Route path="*" element={<NoMatchFound />} />
                </Routes>
      </section>
    </div>
    <Tooltip id="my-tooltip"/>
    </>
  );
  }
}

export default App;
