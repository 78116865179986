import React from "react";
import { Button, Card, CardPreview, SelectTabData, SelectTabEvent, Tab, TabList, typographyStyles } from '@fluentui/react-components';
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { setCurrentLink } from "../../../store/slices/userSlices";
import { withNavigation } from "../../common/hooks/WithNavigation";
import { ErrorMessage, Form, Formik, Field as FormikField } from "formik";
import { AuthData, LLMPageData, LLMState, QuoteData } from "../../../store/types";
import { NavigateFunction } from "react-router-dom";
import { CustomSelect, FormError, FormInput } from "../../common/components/FormikComp";
import { endpoints } from "../../../store/apis/endpoints";
import { setLLMList } from "../../../store/slices/largeLanguageModelSlices";
import axiosApi from "../../../store/apis/axiosApi";
import { toast } from "react-toastify";

interface QuoteFormState {
  selectedTab: any;
  quoteData: QuoteData;
  llmOptions: Array<any>;
}
interface QuoteFormProps {
  userData: AuthData;
  llm: LLMState;
  navigate: NavigateFunction;
  setLLMList(data: LLMPageData): void;
}
class QuoteForm extends React.Component<QuoteFormProps, QuoteFormState> {
  constructor(props: QuoteFormProps) {
    super(props)
    this.state = {
      selectedTab: 1,
      quoteData: {
        full_name: this.props.userData.firstName + " " + this.props.userData.lastName,
        given_name: this.props.userData.firstName,
        surname: this.props.userData.lastName,
        organization: "",
        communication_email: this.props.userData.email,
        industry: "",
        domain: "",
        role: "",
        llm_ids: this.props.llm.selectedLLM.map(data => data.id),
        collab_ids: [],
        platform: []
      },
      llmOptions: []
    }
  }

  componentDidMount(): void {
    this.getLLMList();
  }

  getLLMList() {
    const { userData, setLLMList } = this.props;
    let payload = {
      url: endpoints.advisor.largelanguageModel,
      method: 'GET',
      headers: { Authorization : 'Bearer ' + userData.accessToken }
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        let llmData = res.body.data;
        setLLMList(llmData)
        this.setState({ llmOptions : llmData.results.map((data: any) => {return { value: data.id, label: data.display_name}})})
      } else {
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  handleSubmit(values: QuoteData) {
    this.saveQuote(values);
  }

  saveQuote(data: QuoteData) {
    const { userData } = this.props;
    let payload = {
      url: endpoints.advisor.quote,
      method: 'POST',
      headers: { Authorization : 'Bearer ' + userData.accessToken },
      body: data
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        toast.success("Data Saved Successfully!");
        this.setState({ selectedTab : 5})
      } else {
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  render() {
    let titleText = {...typographyStyles.title3, margin: '20px 0px 5px 0px' };
    return (

      <div style={{ width: '100%', height: '100%', overflow: 'auto', display: 'flex', flexWrap: 'nowrap' as 'nowrap', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <TabList selectedValue={this.state.selectedTab} onTabSelect={(event: SelectTabEvent, data: SelectTabData) => this.setState({ selectedTab: data.value })} defaultSelectedValue="tab1" vertical>
          <Tab value={1}></Tab>
          <Tab value={2}></Tab>
          <Tab value={3}></Tab>
          <Tab value={4}></Tab>
          <Tab value={5}></Tab>
        </TabList>

        <Card style={{
          width: "720px",
          maxWidth: "100%",
        }}>
          <CardPreview style={{backgroundColor: 'white',  padding: '10px', width:'100%', display: 'flex', flexDirection: 'column'}}>
            <Formik
              initialValues={this.state.quoteData}
              enableReinitialize
              onSubmit={this.handleSubmit.bind(this)}
            >
              {({ values, isValid, handleSubmit }) => (
                <Form style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px'}}>
                  {this.state.selectedTab === 1 && 
                    <>
                      <p style={titleText}>Can we start with your name please?</p>
                      <FormikField label="💡 Creator tip: Provide your full name:" required name="full_name"
                        component={FormInput} size='medium' width='40%'>
                      </FormikField>
                      <ErrorMessage name="full_name" component={FormError} />
                      <p style={titleText}>What is your organization name?</p>
                      <FormikField label="💡 Creator tip: Provide your organaization's registerd name:" required name="organization"
                        component={FormInput} size='medium' width='40%'>
                      </FormikField>
                      <ErrorMessage name="organization" component={FormError} />
                      <p style={titleText}>Is this your communication email adddress?*</p>
                      <FormikField label="💡 Creator tip: You will recieve the quote and news letters on this mail:" required name="communication_email"
                        component={FormInput} size='medium' width='40%'>
                      </FormikField>
                      <ErrorMessage name="communication_email" component={FormError} />
                    </>
                  }
                  {this.state.selectedTab === 2 && 
                    <>
                      <p style={titleText}>What is your Industry?</p>
                      <FormikField label="💡 Creator tip: Health Care, Finance & Banking, Etc:" required name="industry"
                        component={FormInput} size='medium' width='40%'>
                      </FormikField>
                      <ErrorMessage name="industry" component={FormError} />
                      <p style={titleText}>What is your Domain?</p>
                      <FormikField label="💡 Creator tip: Investment Banking, Tools and Dye making Etc:" required name="domain"
                        component={FormInput} size='medium' width='40%'>
                      </FormikField>
                      <ErrorMessage name="domain" component={FormError} />
                      <p style={titleText}>What is your Role?</p>
                      <FormikField label="💡 Creator tip: Sales Manager, Transport Manager, Etc:" required name="role"
                        component={FormInput} size='medium' width='40%'>
                      </FormikField>
                      <ErrorMessage name="role" component={FormError} />
                    </>
                  }
                  {this.state.selectedTab === 3 && 
                    <>
                      <p style={titleText}>LLM models to be added to qoute*</p>
                      <FormikField label="💡 Creator tip: Select atleast one model to proceed:" required name="llm_ids"
                        size='medium' width='40%' component={CustomSelect} isMulti={true} 
                        placeholder="Select LLMs" options={this.state.llmOptions}>
                      </FormikField>
                      <ErrorMessage name="llm_ids" component={FormError} />
                      <p style={titleText}>Cloud Platform to be used for LLM Hosting</p>
                      <FormikField label="💡 Creator tip: The choice of Cloud Platform:" name="platform" required
                        size='medium' width='40%' component={CustomSelect} isMulti={false} 
                        placeholder="Select Platform" options={[{value: 'GCP', label: 'Google Cloud'},{ value: 'AWS', label: 'Amazon Web Service'},{ value: 'AML', label: 'Azure ML Studio'}]}>
                      </FormikField>
                      <ErrorMessage name="platform" component={FormError} />
                    </>
                  }
                  {this.state.selectedTab === 4 && 
                    <>
                      <p style={titleText}>Collaborators to be added to qoute</p>
                      <FormikField label="💡 Creator tip: The choice of Collaborators will be added to Output system:" name="collab_ids"
                        size='medium' width='40%' component={CustomSelect} isMulti={true} 
                        placeholder="Select Collaborators" options={[{value: '1', label: 'SAP'},{ value: '2', label: 'ServiceNow'}]}>
                      </FormikField>
                      <ErrorMessage name="collab_ids" component={FormError} />
                      <ErrorMessage name="platform" component={FormError} />
                    </>
                  }
                  <div style={{ display: 'flex', gap: 20, justifyContent: 'flex-start', flexDirection: 'row', margin: '20px 0 15px 0'}}>
                  {(this.state.selectedTab !== 1 && this.state.selectedTab !== 5) && <Button onClick={() => { this.setState({ selectedTab: this.state.selectedTab - 1 }) }}>Previous</Button>}
                  {this.state.selectedTab < 4 && <Button onClick={() => { this.setState({ selectedTab: this.state.selectedTab + 1 }) }}>Next</Button>}
                  {this.state.selectedTab === 4 && 
                    <Button type='submit'>
                      Submit
                    </Button>
                  }
                  </div>
                </Form>
              )}
            </Formik>
            {this.state.selectedTab === 5 && <>
              <h2>Thanks for your information</h2>
              <p>Please explore the our free trials while we will get back to you soon.</p>
              <div style={{ display: 'flex', gap: 20, justifyContent: 'flex-start', flexDirection: 'row', margin: '20px 0 15px 0'}}>
              {this.state.selectedTab === 5 && <Button onClick={() => { this.props.navigate('/home')}}>Go to home</Button>}
              </div>
            </>}
          </CardPreview>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { userData: state.user, llm: state.largeLanguageModel };
}

const mapDispatchToProps = { setCurrentLink, setLLMList };
export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(QuoteForm));
