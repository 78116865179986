import React from "react";
import { Button, Divider, MessageBar, MessageBarBody, MessageBarGroup, Text, typographyStyles } from '@fluentui/react-components';
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { setCurrentLink, setProductData } from "../../../store/slices/userSlices";
import { withNavigation } from "../../common/hooks/WithNavigation";
import './Home.css';
import { Icon } from "@fluentui/react";
import { endpoints } from "../../../store/apis/endpoints";
import axiosApi from "../../../store/apis/axiosApi";
import { toast } from "react-toastify";
import Loader from "../../common/components/Loader";
import { TypeAnimation } from 'react-type-animation';

// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

// const bannerImage: any = require('../../../assets/images/data-science-ai.jpg');
const analyserVideo: any = require('../../../assets/videos/Alex_TheDataAnalyst.mp4');
const advisorVideo: any = require('../../../assets/videos/Ava_TheAdvisor.mp4');
const translatorVideo: any = require('../../../assets/videos/Trent_TheTranslator.mp4');

interface HomeState {
  loader: boolean;
  isError: boolean;
  productData: any;
  promptSeq: any[];
}
class Home extends React.Component<any, HomeState> {
  videoRef: any;
  constructor(props: {} | Readonly<{}>) {
    super(props)
    this.videoRef = React.createRef();

    this.state = {
      loader: true,
      isError: false,
      productData: {},
      promptSeq: [],
    }
  }

  componentDidMount(): void {
    this.getProductData();
  }
  goToContacts() {
    window.location.href = 'https://www.recodesolutions.com/contact/'
  }
  getProductData() {
    const { userData } = this.props;
    let payload = {
      url: endpoints.accounts.product,
      method: 'GET',
      headers: { Authorization: 'Bearer ' + userData.accessToken }
    }
    axiosApi(payload).then(res => {
      if (res.isSuccess) {
        let data = res.body.data.results[0];
        this.setState({promptSeq: [data.description, 1000]}) 
        this.setState({ productData: data }, () => this.setState({ loader: false }));
        this.playVideoFromStart();
        this.props.setProductData(data);
      } else {
        toast.error(res.body.data ? res.body.data.message : res.body);
        this.setState({ loader: false, isError: true})
      }
    });
  }
  playVideoFromStart = () => {
    const video = this.videoRef.current;
    console.log(video)
    if (video) {
      video.currentTime = 0; // Set the video to start from the beginning
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Video is playing
          })
          .catch((error: any) => {
            // Autoplay was prevented. Show a UI element to inform the user.
            console.log('Autoplay prevented:', error);
          });
      }
    }
  }
  render() {
    // const docs = [
    //   { uri: "http://testdriveai.recodesolutions.com:8000/api/v1/shared/documents/" },
    // ];
    const { isAuthenticated } = this.props.userData;

    const largeIconStyle = {
      marginTop: '77px',
      fontSize: 100, // Adjust the size as needed
      height: 100,
      width: 100
    };

    return (<>
      {this.state.loader ? <Loader /> :
        this.state.isError ? 
        <MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
          <MessageBar intent="error" layout="multiline">
            <MessageBarBody>
              There seems to be an issue, Please try after sometime.
            </MessageBarBody>
          </MessageBar>
        </MessageBarGroup> :
        <div className="home-page">
          <div className="banner">

            <div className="content">
              <div className="col col-1">
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <h1 style={typographyStyles.largeTitle}>{this.state.productData?.title}</h1>
                  <div>
                    <img src={this.state.productData?.image} alt='banner' width={235} height={235} style={{ float: 'left', marginRight: '10px' }} />
                    <div className="css-typing" style={{height: '230px'}}>
                      <TypeAnimation
                        sequence={this.state.promptSeq}
                        wrapper="p"
                        speed={50}
                        style={typographyStyles.body1Strong}
                        repeat={0}
                      /></div>
                    <div className="actionBar">
                      <Button color="#454e9b" appearance="primary" style={{ width: '200px' }} onClick={() => {
                        if (isAuthenticated) {
                          this.setState({ promptSeq: ['Excellent pick! Preparing for your workspace now... Be sure to read the instructions below. Happy prompting!', 1000] });
                          this.props.navigate('/easy-to-start')

                        }
                        else {
                          this.setState({ promptSeq: ['Awsome! Please take a moment to signin and Be sure to read the instructions below. Happy prompting!', 1000] });
                          this.props.navigate('/sign-in'); this.props.setCurrentLink('/easy-to-start')
                        }
                      }}
                      >
                        Test Drive Now!</Button>
                      <Button color="#cb2032" appearance="secondary" style={{ width: '200px' }} onClick={() => this.goToContacts()}>
                        Contact Us</Button>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col col-2">
                {this.state.productData?.slug === 'theadvisor' && <video className="img-container" ref={this.videoRef} autoPlay style={{ zIndex: 1 }} controls>
                  <source src={advisorVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>}
                {this.state.productData?.slug === 'theanalyser' && <video className="img-container" ref={this.videoRef} autoPlay style={{ zIndex: 1 }} controls>
                  <source src={analyserVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>}
                {this.state.productData?.slug === 'thetranslator' && <video className="img-container" ref={this.videoRef} autoPlay style={{ zIndex: 1 }} controls>
                  <source src={translatorVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>}
              </div>
            </div>
          </div>
          <Divider style={{ width: '100%', marginTop: '20px' }}><Text as='h2' style={typographyStyles.title3}>How can I help you?</Text></Divider>
          <div className="instructions">
            <div className="timeline">

              <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                <div className="timeline__event__icon ">
                  <Text as='h1' style={typographyStyles.display}>1</Text>
                </div>
                <div className="timeline__event__date">
                  <Icon iconName="Rocket" style={largeIconStyle} />
                </div>
                <div className="timeline__event__content " >
                  <div className="timeline__event__title">
                    Signup For Test-drive
                  </div>
                  <div className="timeline__event__description">
                    <p>Click on <b>Test Drive Now!</b> above and follow the below steps to start your journey through the Application</p>
                  </div>
                </div>
              </div>


              <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                <div className="timeline__event__icon">
                  <Text as='h1' style={typographyStyles.display}>2</Text>

                </div>
                <div className="timeline__event__date">
                  <Icon iconName="BulletedList" style={largeIconStyle} />
                </div>
                <div className="timeline__event__content">
                  <div className="timeline__event__title">
                    Choose LLM(s)
                  </div>
                  <div className="timeline__event__description">
                    <p>Selecting the appropriate Large Language Model (LLM) for your process that meets the business needs. There are options for selecting your desired LLMs to compare metrics via score card in the next stage.</p>
                  </div>
                </div>
              </div>


              <div className="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                <div className="timeline__event__icon">
                  <Text as='h1' style={typographyStyles.display}>3</Text>

                </div>
                <div className="timeline__event__date">
                  <Icon iconName="BarChartVertical" style={largeIconStyle} />
                </div>
                <div className="timeline__event__content">
                  <div className="timeline__event__title">
                    Compare Chosen LLM(s)
                  </div>
                  <div className="timeline__event__description">
                    <p>Benchmark the LLMs using our "Scorecard" by analysing the factors like infrastructure, model size, pricing, performance, hallucinations, NLP processing capacity, training data, and so on, which assists in selecting the right LLM for evaluating performance and quality.</p>
                  </div>

                </div>
              </div>


              <div className="timeline__event animated fadeInUp timeline__event--type2">
                <div className="timeline__event__icon">
                  <Text as='h1' style={typographyStyles.display}>4</Text>

                </div>
                <div className="timeline__event__date">
                  <Icon iconName="Upload" style={largeIconStyle} />
                </div>
                <div className="timeline__event__content">
                  <div className="timeline__event__title">
                    {this.state.productData?.slug === 'theadvisor' && <>Upload Your PDF Document</>}
                    {this.state.productData?.slug === 'theanalyser' && <>Upload Your Spreadsheet Data</>}
                    {this.state.productData?.slug === 'thetranslator' && <>Upload Your Audio File</>}
                  </div>
                  <div className="timeline__event__description">
                    {this.state.productData?.slug === 'theadvisor' && <p>Upload your data as PDF file from a shared folder or other place. You can view your uploaded files in this window.</p>}
                    {this.state.productData?.slug === 'theanalyser' && <p>Upload your data as Excel file from a shared folder or other place. You can view your uploaded files in this window.</p>}
                    {this.state.productData?.slug === 'thetranslator' && <p>Upload your data as MP3 file from a shared folder or other place. You can view your uploaded files in this window.</p>}
                  </div>
                </div>
              </div>

              <div className="timeline__event animated fadeInUp timeline__event--type1">
                <div className="timeline__event__icon">
                  <Text as='h1' style={typographyStyles.display}>5</Text>

                </div>
                <div className="timeline__event__date">
                  <Icon iconName="TextField" style={largeIconStyle} />
                </div>
                <div className="timeline__event__content">
                  <div className="timeline__event__title">
                    Provide Your Context
                  </div>
                  <div className="timeline__event__description">
                    <p>Type the basic information or hint to aid LLMs in receiving relevant responses. Base information also serves as a simple dataset for the LLM.</p>
                  </div>
                </div>
              </div>
              <div className="timeline__event animated fadeInUp timeline__event--type2">
                <div className="timeline__event__icon">
                  <Text as='h1' style={typographyStyles.display}>6</Text>

                </div>
                <div className="timeline__event__date">
                  <Icon iconName="Chat" style={largeIconStyle} />
                </div>
                <div className="timeline__event__content">
                  <div className="timeline__event__title">
                    Converse with LLM(s)
                  </div>
                  <div className="timeline__event__description">
                    <p>Start a conversation with many LLMs to acquire relevant results for your inputs and queries, which will be published dependent on your chosen digital worker.</p>
                  </div>
                </div>
              </div>

              <div className="timeline__event animated fadeInUp timeline__event--type1">
                <div className="timeline__event__icon">
                  <Text as='h1' style={typographyStyles.display}>7</Text>

                </div>
                <div className="timeline__event__date">
                  <Icon iconName="ExploreData" style={largeIconStyle} />
                </div>
                <div className="timeline__event__content">
                  <div className="timeline__event__title">
                    Evaluate And Decide
                  </div>
                  <div className="timeline__event__description">
                    <p>Drill down the LLM's responses by passing multiple inputs and questions, analyse them on screen, and then rate them with stars based on our LLM evaluation rules.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*         <Divider style={{ width: '100%', marginTop: '100px' }}><Text as='h2' style={typographyStyles.title3}>Our Readings on LLMs Benchmark</Text></Divider>
 */}        <div>

            {/* <DocViewer
              documents={docs}
              pluginRenderers={DocViewerRenderers}
              config={{ header: { disableHeader: true } }}
            />  */}
          </div>
          <div id="homepage_footer"></div>
        </div>}</>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { userData: state.user };
}

const mapDispatchToProps = { setCurrentLink, setProductData };

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(Home));
