import * as React from 'react';
import { Button } from '@fluentui/react-components';
import { LockClosed28Regular } from "@fluentui/react-icons";
import axiosApi from '../../store/apis/axiosApi';
import { endpoints } from '../../store/apis/endpoints';
import Loader from '../common/components/Loader';
import { Formik, Form, Field as FormikField, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FormError, FormInput } from '../common/components/FormikComp';
import { toast } from 'react-toastify';
import { withNavigation } from '../common/hooks/WithNavigation';

interface SignUpState {
  loader: boolean;
  industry: Array<String>;
  role: Array<String>;
  functionalArea: Array<String>
}

const signUpSchema = Yup.object().shape({
  given_name: Yup.string().required('Please Enter First Name').matches(/^[a-zA-Z\s.-]+$/, { message: 'First Name must be alphabetic'}),
  surname: Yup.string().required('Please Enter Last Name').matches(/^[a-zA-Z\s.-]+$/, { message: 'Lat Name must be alphabetic'}),
  email: Yup.string().email('Invalid Email').required('Please Enter Email'),
  password: Yup.string().required('Please Enter Password'),
  //industry: Yup.string().required('Please Select Industry'),
  //functional_area: Yup.string().required('Please Select Functional Area'),
  //role: Yup.string().required('Please Select Role'),
  confirm_password: Yup.string().required('Please Confirm Password')
    .oneOf([Yup.ref('password')], "Does not match with Password!")
});

class SignUp extends React.Component<any, SignUpState> {
  constructor(props:any) {
    super(props);
    this.state = {
      loader: true,
      industry: [],
      role: [],
      functionalArea: []
    }
  }

  componentDidMount(): void {
    this.getIndustryData();
    this.getFunctionalAreaData();
    this.getRoleData();
  }

  getIndustryData() {
    let payload = {
      url: `${endpoints.accounts.industry}?length=100`,
      method: 'GET'
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
      this.setState({ industry: res.body.data.results.map((v: any) => { return {value: v.id, label: v.name}})

      });
      } else {
        this.setState({ loader: false});
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  getFunctionalAreaData() {
    let payload = {
      url: `${endpoints.accounts.functionalArea}?length=100`,
      method: 'GET'
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
      this.setState({ functionalArea: res.body.data.results.map((v: any) => { return {value: v.id, label: v.name}})

      });
      } else {
        this.setState({ loader: false});
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  getRoleData() {
    let payload = {
      url: `${endpoints.accounts.role}?length=100`,
      method: 'GET'
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
      this.setState({ role: res.body.data.results.map((v: any) => { return {value: v.id, label: v.name}}),
        loader: false
      });
      } else {
        this.setState({ loader: false});
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  handleSubmit(event: any) {
    this.setState({ loader: true});
    //Register user here with a service call then navigate to login page
    let payload = {
      url: endpoints.accounts.register,
      method: 'POST',
      body: event
    }
    this.registerAccount(payload);
  }

  registerAccount(payload: any) {
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        this.setState({ loader: false}, () => {
          toast.success("Our Sales team will reach out to help you Test-Drive");
          this.props.navigate('/sign-in');
        });
      } else {
        this.setState({ loader: false});
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  render() {
    return (
      <>
        {this.state.loader && <Loader/>}
        <div style={{ display: 'flex', justifyContent: 'center', alignSelf:'center'}}>
          <div
            style={{ maxWidth: 1000, minWidth: 400,
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{width:'100%', boxShadow: '0 0 2px rgba(0,0,0,0.24), 0 8px 16px rgba(0,0,0,0.28)',
              display: 'flex', flexDirection: 'column', gap: 10, borderRadius: 5, padding: '10px 0px'
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center', borderRadius: '10000px', backgroundColor:'#4f52b2', padding:5,
                  }}>
                  <LockClosed28Regular color='#ffffff'/>
                </div>
                <h2 style={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: '400',
                  fontSize: '1.5rem', lineHeight: '1.334', margin:5}}>Sign Up</h2>
              </div>
              <Formik
                initialValues={{ given_name: '', surname: '', email: '', password: '', confirm_password: '',
                  industry: '', functional_area: '', role: ''
                }}
                onSubmit={this.handleSubmit.bind(this)}
                validationSchema={signUpSchema}
              >
                {({ values }) => (
                  <Form style={{ margin: '0px 15px', display: 'flex', flexDirection: 'column'}}>
                    <div style={{ display: 'flex', gap: 20}}>
                      <div>
                        <FormikField label="First Name" required name="given_name"
                        component={FormInput} size='large'>
                        </FormikField>
                        <ErrorMessage name="given_name" component={FormError} />
                      </div>
                      <div>
                        <FormikField label="Last Name" required name="surname"
                          component={FormInput} size='large'>
                        </FormikField>
                        <ErrorMessage name="surname" component={FormError} />
                      </div>
                    </div>
                    <div style={{ display: 'block', gap: 20}}>
                      <div>
                        <FormikField label="Work Email" required name="email"
                          component={FormInput} size='large'>
                        </FormikField>
                        <ErrorMessage name="email" component={FormError} />
                      </div>
                      {/* <div style={{ width: '100%'}}>
                        <FormikField label="Industry" required name="industry"
                          size='large' component={CustomSelect} isMulti={false} 
                          options={this.state.industry} width='100%'
                        >
                        </FormikField>
                        <ErrorMessage name="industry" component={FormError} />
                      </div> */}
                    </div>
                    {/* <div style={{ display: 'flex', gap: 20}}>
                      <div style={{ width: '100%'}}>
                        <FormikField label="Functional Area" required name="functional_area"
                          size='large' component={CustomSelect} isMulti={false} 
                          options={this.state.functionalArea} width='100%'
                        >
                        </FormikField>
                        <ErrorMessage name="functional_area" component={FormError} />
                      </div>
                      <div style={{ width: '100%'}}>
                        <FormikField label="Role" required name="role"
                          size='large' component={CustomSelect} isMulti={false} 
                          options={this.state.role} width='100%' 
                        >
                        </FormikField>
                        <ErrorMessage name="role" component={FormError} />
                      </div>
                    </div> */}
                    <div style={{ display: 'flex', gap: 20}}>
                      <div>
                        <FormikField label="Password" required name="password" type="password"
                          component={FormInput} size='large'>
                        </FormikField>
                        <ErrorMessage name="password" component={FormError} />
                      </div>
                      <div>
                        <FormikField label="Confirm Password" required name="confirm_password" type="password"
                          component={FormInput} size='large'>
                        </FormikField>
                        <ErrorMessage name="confirm_password" component={FormError} />
                      </div>
                    </div>
                    <Button appearance='primary' type='submit' size='large' style={{width: '100%', marginTop: 20}} >
                      Sign Up
                    </Button>
                  </Form>
                )} 
              </Formik>
              <div style={{ display: 'flex', justifyContent:'end', marginRight:15, alignItems: 'center'}}>
                Already have an account?<Button appearance='transparent' size='medium' onClick={() => this.props.navigate('/sign-in')}>{" "}Sign In</Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


export default withNavigation(SignUp);