import { Card, Text } from "@fluentui/react-components";
import React from "react";
import { File } from "../../store/types";
import { Document48Regular } from "@fluentui/react-icons";

interface DataCardProps {
  data: File;
  selected: File[];
  onSelect(index: File): void;
}

interface DataCardState {
  hover: boolean;
}
class DataCard extends React.Component<DataCardProps, DataCardState> {
  constructor(props: DataCardProps) {
    super(props);
    this.state = {
      hover: false 
    }
  }
  isSelected(data: File):boolean{
    if(this.props.selected instanceof Array){
      return this.props.selected.find(file=>file.id === data.id)!=undefined;
    }
    return false;
  }
  render() {
    const { data, onSelect } = this.props;

    return (
      <Card as="div" size="small"
        style={{ margin:4, width: 'auto', height: 'auto', minHeight:'min-content',
          border : this.isSelected(data) ? '1px solid #5b5fc7' : this.state.hover ? '1px solid #5b5fc7' : '1px solid #cccccc',
        display: 'flex', flexDirection: 'row'}
        }
        onMouseEnter={() => { this.setState({hover: true});}}
        onMouseLeave={() => { this.setState({hover: false});}}
        selected={this.isSelected(data)}
        onSelectionChange={() => {onSelect(data)}}
      >
        <Document48Regular color="#5b5fc7"/>
        <div style={{margin: '4px 8px'}}>
          <div style={{ marginBottom:'4px'}}>
            <Text style={{ color:'#555555', fontWeight:'bold'}}>{data.filename}</Text>
          </div>
          <div>
            {new Date(data.created_at).toLocaleString()}
          </div>
        </div>
      </Card>
    )
  }
}

export default DataCard;