import React from "react";
import { withNavigation } from "../common/hooks/WithNavigation";
import { Button } from "@fluentui/react-components";
import ExploreLLM from "./ExploreLLM";
import { ArrowCircleLeft28Filled, CommentMultiple28Filled, ArrowCircleRight28Filled } from '@fluentui/react-icons';
import IngestYourData from "./IngestYourData";
import Loader from "../common/components/Loader";
import { endpoints } from "../../store/apis/endpoints";
import axiosApi from "../../store/apis/axiosApi";
import { toast } from "react-toastify";
import { AuthData, Configuration, Conversation, FileState, LLMState } from "../../store/types";
import { RootState } from "../../store";
import { setConfig, setConversation } from "../../store/slices/conversationHistorySlices";
import { connect } from "react-redux";
import { NavigateFunction } from "react-router-dom";
import ContextualizationSettings from "./ContextualizationSettings";

interface EasyStartState {
  selectedTab: any;
  loader: boolean;
  context: string;
}
interface EasyStartProps {
  userData: AuthData;
  file: FileState;
  llm: LLMState;
  setConfig(data: Configuration): void;
  setConversation(data: Conversation): void;
  navigate: NavigateFunction;
}
class EasyStart extends React.Component<EasyStartProps, EasyStartState> {
  constructor(props: EasyStartProps) {
    super(props)
    this.state = {
      selectedTab: 1,
      loader: false,
      context: ''
    }
  }

  handleNext() {
    let productId = localStorage.getItem('productId');
    if (this.state.selectedTab !== 3) {
      if (this.state.selectedTab === 1) {
        if (this.props.llm.selectedLLM.length <= 0)
          toast.error("Please select atleast 1 LLM to continue");
        else this.setState({ selectedTab: this.state.selectedTab + 1 })
      }
      else {
        if(productId === 'theadvisor') {
          if (this.props.file.selectedFiles === null)
            toast.error("Please select a document continue");
          else this.setState({ selectedTab: this.state.selectedTab + 1 })
        } else {
          this.setState({ loader: true });
          this.getConfiguration();
        }
      }
    }
    else {
      if(this.state.context === '') {
        toast.error('Please provide PrePrompt to continue');
        return;
      } else {
        this.setState({ loader: true });
        this.getConfiguration();
      }
    }
  }

  getConfiguration() {
    const { userData, setConfig } = this.props;
    let payload = {
      url: endpoints.advisor.configuration,
      method: 'GET',
      headers: { Authorization: 'Bearer ' + userData.accessToken }
    }
    axiosApi(payload).then(res => {
      if (res.isSuccess) {
        const config = res.body.data.results[0];
        setConfig(config);
        this.createConversation(config.id);
      } else {
        toast.error("Error Occurred: " + res.body.data ? res.body.data instanceof String ? res.body.data : res.body.data.message : res.body);
      }
    });
  }

  createConversation(configId: string) {
    let productId = localStorage.getItem('productId');
    const { file, userData, llm, setConversation } = this.props;
    let payload = {
      url: endpoints.advisor.conversation,
      method: 'POST',
      headers: { Authorization: 'Bearer ' + userData.accessToken },
      body: productId === 'theadvisor' ? {
        user: userData.userId,
        configuration: configId,
        //document: file.selectedFiles.map(file=>file.id),
        document: file.selectedFiles[0].id,
        llm: llm.selectedLLM.map(llms => { return llms.id }),
        prompt: this.state.context,
        doc_ids : file.selectedFiles.map(file=>file.id)
      } : {
        user: userData.userId,
        configuration: configId,
        //document: file.selectedFiles.map(file=>file.id),
        document: file.selectedFiles[0].id,
        llm: llm.selectedLLM.map(llms => { return llms.id }),
        prompt: this.state.context
      }
    }
    axiosApi(payload).then(res => {
      if (res.isSuccess) {
        setConversation(res.body.data);
        this.setState({ loader: false })
        this.props.navigate('/my-workspace');
      } else {
        toast.error("Error Occurred: " + res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  render() {
        let productId = localStorage.getItem('productId');

    return (
      <div style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'start', width: '100%' }}>
        {/* <TabList selectedValue={this.state.selectedTab} 
          onTabSelect={(event: SelectTabEvent, data: SelectTabData) => this.setState({ selectedTab: data.value })}
          defaultSelectedValue={1}
          style={{ justifyContent: 'space-around'}}
        >
          <Tab value={1}>{this.state.selectedTab === 1 ? <b>Select LLMs</b>: 'Select LLMs'}</Tab>
          <Tab value={2}>{this.state.selectedTab === 2 ? <b>Ingest Data</b>: 'Ingest Data'}</Tab>
          <Tab value={3}>{this.state.selectedTab === 3 ? <b>Configure your I/O(Only for final product)</b>: 'Configure your I/O (Only for final product)'}</Tab>
        </TabList> */}
        {this.state.selectedTab === 1 && <ExploreLLM></ExploreLLM>}
        {this.state.selectedTab === 2 && <IngestYourData></IngestYourData>}
        {(this.state.selectedTab === 3 && productId==='theadvisor') && 
          <ContextualizationSettings setContext={(data: string) => this.setState({ context: data})}/>}
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: this.state.selectedTab === 1 ? 'end' : 'space-between',
          padding: 8
        }}
        >
          {this.state.selectedTab !== 1 &&
            <Button onClick={() => { this.setState({ selectedTab: this.state.selectedTab - 1 }) }}
              style={{ marginLeft: 8 }} size="large"
              appearance="outline" icon={<ArrowCircleLeft28Filled />} iconPosition="before"
            >
              Back
            </Button>
          }
          {this.state.selectedTab === 1 && (<Button onClick={this.handleNext.bind(this)}
            appearance="primary" iconPosition="after" size="large" style={{ marginRight: 8 }}
            icon={<ArrowCircleRight28Filled />}
          >
            Next
          </Button>)}
          {(productId ==='theadvisor' && this.state.selectedTab === 2) && (<Button onClick={this.handleNext.bind(this)}
            appearance="primary" iconPosition="after" size="large" style={{ marginRight: 8 }}
            icon={<ArrowCircleRight28Filled />}
          >
            Next
          </Button>)}
          {(productId !=='theadvisor' && this.state.selectedTab === 2) && (<Button onClick={this.handleNext.bind(this)}
            appearance="primary" iconPosition="after" size="large" style={{ marginRight: 8 }}
            icon={<ArrowCircleRight28Filled />}
          >
            Start Prompting
          </Button>)}
          {(productId ==='theadvisor' && this.state.selectedTab === 3) && (<Button onClick={this.handleNext.bind(this)}
            appearance="primary" iconPosition="after" size="large" style={{ marginRight: 8 }}
            icon={<CommentMultiple28Filled />}
          >
            Start Prompting
          </Button>)}
        </div>
        {this.state.loader && <Loader />}
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return {
    userData: state.user,
    conv: state.conversationHistory,
    file: state.files,
    llm: state.largeLanguageModel
  };
}

const mapDispatchToProps = { setConfig, setConversation };
export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(EasyStart));