import React from "react";
import { withNavigation } from "../common/hooks/WithNavigation";
import { setCurrentLink } from "../../store/slices/userSlices";
import { connect } from "react-redux";
import { setChat, setConfig, setConvPageData, setConversation, setConversationList } from "../../store/slices/conversationHistorySlices";
import { endpoints } from "../../store/apis/endpoints";
import axiosApi from "../../store/apis/axiosApi";
import { toast } from "react-toastify";
import { setDocList, setDocPageData } from "../../store/slices/fileSlices";
import { setLLMList, setParameters, setSelectedLLM } from "../../store/slices/largeLanguageModelSlices";
import { Location, NavigateFunction } from "react-router-dom";
import { RootState } from "../../store";
import { AuthData, ChatMessage, Configuration, Conversation, ConversationChats, ConversationPageData, ConversationState, File, FilePageData, LLM, LLMPageData, LLMState, Parameters } from "../../store/types";
import { Button, Drawer, DrawerBody, DrawerHeader, MessageBar, MessageBarBody, MessageBarGroup, MessageBarTitle, SelectTabData, SelectTabEvent, Tab, TabList } from "@fluentui/react-components";
import { Dismiss24Regular, Send24Regular } from "@fluentui/react-icons";
import ConversationHistory from "./ConversationHistory";
import Documents from "./Documents";
import CurrentConversation from "./CurrentConversation";
import CustomDrawer from "../common/components/CustomDrawer";
import { MessageContentType, MessageStatus, SendBox } from "@azure/communication-react";
import ChatGrid from "./ChatGrid";
import Collaborators from "./Collaborators";
import Loader from "../common/components/Loader";
import InlineLoader from "../common/components/InlineLoader";

interface WorspaceState {
  loader: boolean;
  chatLoader: boolean;
  convChats: Array<ConversationChats>;
  drawerOpen: boolean;
  selectedTab: string | unknown;
  selectedTab2: string | unknown;
  isError: boolean;
} 
interface WorkspaceProps {
  location: Location<any>;
  setCurrentLink(link: string): void;
  userData: AuthData;
  llm: LLMState;
  setConversationList(data: Array<Conversation>): void;
  setConvPageData(data: ConversationPageData): void;
  conv: ConversationState;
  setChat(data: Array<ChatMessage>): void;
  setDocList(data: Array<File>): void;
  setDocPageData(data: FilePageData): void;
  setSelectedLLM(data: LLM): void;
  setConversation(data: Conversation | null): void;
  setConfig(data: Configuration): void;
  setLLMList(data: LLMPageData): void;
  navigate: NavigateFunction;
  setParameters(data: Array<Parameters>): void;
}

class Workspace extends React.Component<WorkspaceProps, WorspaceState> {
  constructor(props: WorkspaceProps){
    super(props);
    this.state = {
      drawerOpen: true,
      loader: true,
      convChats: [],
      selectedTab: "conversations",
      selectedTab2: "1",
      chatLoader: true,
      isError: false
    }
  }

  componentDidMount(): void {
    this.props.setCurrentLink('/my-workspace');
    this.getAllConversation(0);
    this.getConfiguration();
    this.getParametersData();
    if(this.props.llm.llmList.results.length <= 0)
      this.getLLMList();
  }

  getConfiguration() {
    const { userData, setConfig } = this.props;
    let payload = {
      url: endpoints.advisor.configuration,
      method: 'GET',
      headers: { Authorization : 'Bearer ' + userData.accessToken }
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        const config = res.body.data.results[0];
        setConfig(config);
      } else {
        toast.error("Error Occurred: " + (res.body instanceof String) ? res.body : res.body.data instanceof String ? res.body.data : res.body.data.message);
      }
    });
  }

  getParametersData() {
    const { userData, setParameters } = this.props;
    let payload = {
      url: endpoints.scoreCard.parameter,
      method: 'GET',
      headers: { Authorization : 'Bearer ' + userData.accessToken }
    }
    axiosApi(payload).then(res => {
    if(res.isSuccess) {
      let productParameters = res.body.data.results;
      setParameters(productParameters.filter((v: any) => v.type === 'PRODUCT')); 
    } else {
      toast.error(res.body.data ? res.body.data.message : res.body);
    }
    });
  }

  getAllConversation(page: number) {    
    const { userData, setConversationList, conv, setConvPageData} = this.props;
    let payload = {
      url: `${endpoints.advisor.conversation}?${page === 0 ? '' : '&page='+page}&length=5`,
      method: 'GET',
      headers: { Authorization : 'Bearer ' + userData.accessToken }
    } 
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        let conversations = res.body.data.results;
        setConvPageData(res.body.data);
        if(page ===0)
          setConversationList(conversations);
        if(conv.conversation !== null && conv.conversation !== undefined) {
          let current = 1, length = conv.conversation.llm.length;
          conv.conversation.llm.forEach(llmId => {
            this.getChatData(conv.conversation, llmId, current, length);
            current++;
          })
        } else this.setState({ chatLoader: false })
        this.setState({ loader: false })
      } else {
        toast.error("Error Occurred: " + (res.body instanceof String) ? res.body : res.body.data? res.body.data.message : res.body.data);
        this.setState({ isError: true});
      }
    });
  }

  getChatData(conversation: Conversation | null, llmId: string | undefined, current: number, length: number) {  
    const { userData, conv, setChat } = this.props;
    let payload = {
      url: endpoints.advisor.chat + 
        '?conversation_id=' + (conversation === null ? conv.conversation?.id : conversation.id) + 
        '&llm_id=' + llmId,
      method: 'GET',
      headers: { Authorization : 'Bearer ' + userData.accessToken },
    } 
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        let chat = res.body.data;
        setChat(chat);
        this.updateConversationChats(llmId, chat.results, chat.previous, current, length);
      } else {
        toast.error("Error Occurred: " + res.body.data ? res.body.data : res.body);
      }
    });
  }

  updateConversationChats(llmId: string | undefined, chat: Array<ChatMessage>, previous: number | null, current: number, length: number) {
    let items = this.state.convChats;
    let index = items.findIndex(v => v.llmId === llmId);
    if(items.length <=0 || index < 0) {
      items.push({ llmId: llmId || '', chat: chat});
      this.setState({convChats : items});
    } else {
      const updatedItems = items.map(v => {
        if(v.llmId === llmId) {
          let temp = [...v.chat];
          if(previous === 0 && current === 0 && length === 0) {
            temp.pop();
          }
          return { 
            ...v, 
            chat: previous === null ? chat : [...temp, ...chat]};
        }
        return v;
      });
      this.setState({convChats : updatedItems});      
    }
    if(current === length)
      this.setState({ chatLoader: false });
  }

  getDocList(page: number) {
    const { userData, setDocPageData, setDocList } = this.props;
    let payload = {
      url: `${endpoints.advisor.document}?${page === 0 ? '' : '&page='+page}`,
      method: 'GET',
      headers: { Authorization : 'Bearer ' + userData.accessToken }
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        setDocPageData(res.body.data);
        if(page === 0)
          setDocList(res.body.data.results);
      } else {
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  getLLMDetail(llmId: string | null) {  
    const { userData, setSelectedLLM } = this.props;
    let payload = {
      url: endpoints.advisor.largelanguageModel + llmId + '/',
      method: 'GET',
      headers: { Authorization : 'Bearer ' + userData.accessToken },
    } 
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        let llm = res.body.data;
        setSelectedLLM(llm);
      } else {
        toast.error("Error Occurred: " + res.body.data ? res.body.data : res.body);
      }
    });
  }

  deleteConversation(convId: string) {   
    this.setState({ convChats : []}); 
    const { userData, setConversation } = this.props;
    setConversation(null);
    let payload = {
      url: endpoints.advisor.conversation + convId + '/',
      method: 'DELETE',
      headers: { Authorization : 'Bearer ' + userData.accessToken }
    } 
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        this.getAllConversation(0);
      } else {
        toast.error("Error Occurred: " + res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  getLLMList() {
    const { userData, setLLMList } = this.props;
    let payload = {
      url: endpoints.advisor.largelanguageModel,
      method: 'GET',
      headers: { Authorization : 'Bearer ' + userData.accessToken }
    }
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        setLLMList(res.body.data)
      } else {
        toast.error(res.body.data ? res.body.data.message : res.body);
      }
    });
  }

  onTabSelect(event: SelectTabEvent, data: SelectTabData) {
    this.setState({ selectedTab: data.value});
  };

  async sendMessage(msg: string) {
    const addedChatItems: ChatMessage[] = [{
      messageType: 'chat',
      contentType: 'text' as MessageContentType,
      senderId: '1',
      senderDisplayName: 'Me',
      messageId: Math.random().toString(),
      content: msg,
      createdOn: new Date(),
      mine: true,
      attached: false,
      status: 'seen' as MessageStatus,
      scoreCardScorecardChat: []
    },{
      messageType: 'chat',
      contentType: 'html' as MessageContentType,
      senderId: '2',
      senderDisplayName: 'Bot',
      messageId: Math.random().toString(),
      content: "",
      createdOn: new Date(),
      mine: false,
      attached: false,
      status: 'seen',
      scoreCardScorecardChat:[]
    }];
    const updateChats = this.state.convChats.map(v => {
      return { ...v, chat : [ ...v.chat, ...addedChatItems]};
    })
    this.setState({ convChats: updateChats});
    const { conversation } = this.props.conv;
    conversation?.llm.forEach(data => {
      this.askQuestion(msg, conversation.id, data);
    })
  }

  askQuestion(msg: string, conversationId: string, llmId: string) {
    const { userData } = this.props;
    let payload = {
      url: userData.product.slug === "theadvisor" ? endpoints.advisor.askQuestionMultiDoc : endpoints.advisor.askQuestion,
      method: 'POST',
      headers: { Authorization : 'Bearer ' + userData.accessToken },
      body: {
        quetion: msg,
        conversation: conversationId,
        llm: llmId
      }
    } 
    axiosApi(payload).then(res => {
      if(res.isSuccess) {
        this.updateConversationChats(llmId, res.body.data, null, 0, 0);
      } else {
        // toast.error("Error Occurred: " + res.body.data ? res.body.data : res.body);
        const addedChatItems: ChatMessage[] = [{
          messageType: 'chat',
          contentType: 'html' as MessageContentType,
          senderId: '2',
          senderDisplayName: 'Bot',
          messageId: Math.random().toString(),
          content: "There seems to be an issue, Please try again later",
          createdOn: new Date(),
          mine: false,
          attached: false,
          status: 'seen',
          scoreCardScorecardChat:[]
        }];
        this.updateConversationChats(llmId, addedChatItems,0, 0, 0);
      }
    });
  }

  changeConversationData(data: Conversation) {
    this.setState({chatLoader: true, convChats : []}, () => {
      let current = 1, length = data.llm.length;
      data.llm.forEach(llmId => {
        this.getChatData(data, llmId, current, length);
        current++;
      })
    })
  }

  render() {
    return (
    <> {this.state.loader ? <Loader/> : 
      this.state.isError ? 
        <MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
        <MessageBar intent="error" layout="multiline">
          <MessageBarBody>
            There seems to be an issue, Please try after sometime.
          </MessageBarBody>
        </MessageBar>
        </MessageBarGroup> :
      <>
        <Drawer style={{ height: '100%', minWidth: 350}}
          type={"inline"}
          separator
          open={this.state.drawerOpen}>   
          <DrawerHeader style={{ flexDirection: 'row', borderBottom:'2px solid #dddddd', padding: 10, justifyContent: 'space-between'}}>
            <TabList selectedValue={this.state.selectedTab} onTabSelect={this.onTabSelect.bind(this)}
              style={{ width: 'auto'}}
            >
              <Tab value="conversations">Conversations</Tab>
              <Tab value="data">Data</Tab>
          </TabList>
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => this.setState({ drawerOpen:false})}
            />
          </DrawerHeader>
          <DrawerBody style={{ display: 'flex', padding: 0}}>
            { this.state.selectedTab === "conversations" && 
              <ConversationHistory getChatData={this.getChatData.bind(this)}
                getLLMDetail={this.getLLMDetail.bind(this)} deleteConversation={this.deleteConversation.bind(this)}
                getAllConversation={this.getAllConversation.bind(this)} changeConversationData={this.changeConversationData.bind(this)}
              />}
            { this.state.selectedTab === "data" && 
              <Documents getDocList={this.getDocList.bind(this)} getAllConversation={this.getAllConversation.bind(this)}/>
            }
            { this.state.selectedTab === "collaborators" && 
              <Collaborators />
            }
          </DrawerBody>
        </Drawer>
        <CustomDrawer open={!this.state.drawerOpen} position="top" width={400}
          children={<CurrentConversation onChangeConv={() => this.setState({ drawerOpen: true})} 
            conversation={this.props.conv.conversation} 
          />}
        />
        <CustomDrawer open={true} position="bottom" width={600}
          children={<div style={{ padding: '5px 10px', backgroundColor: 'white'}}><SendBox
            disabled={false}
            onSendMessage={this.sendMessage.bind(this)}
            onTyping={async () => {
              return;
            }}
            onRenderIcon={() => <Send24Regular />}
          /></div>}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '100%', overflow: 'auto', width: '100%'}}>
        {this.state.chatLoader ? <InlineLoader/> : <>
          {(this.props.conv.conversation === null || this.props.conv.conversation === undefined) && (<MessageBarGroup animate={'both' as 'both'} style={{margin: '10px'}}>
              <MessageBar intent="warning" layout="multiline">
                <MessageBarBody>
                  <MessageBarTitle>{this.props.userData.product.title.substring(0, this.props.userData.product.title.indexOf(","))}: </MessageBarTitle>
                  "No Conversation selected to view. Please select a conversation or create new one."
                </MessageBarBody>
              </MessageBar>
            </MessageBarGroup>)}
          
            {this.state.convChats.length > 0 && (<div style={{ width: '100%', display: 'flex', height: '100%', overflow: 'auto'}}>
              {this.state.convChats.length > 0 ? <ChatGrid convChats={this.state.convChats} llmList={this.props.llm.llmList.results}/> : <></>}
            </div>)}</>
          }
          {/* <div style={{ width: '35%', display: 'flex', flexDirection: 'column', backgroundColor: 'white'}}>
          <TabList selectedValue={this.state.selectedTab2} onTabSelect={(event: SelectTabEvent, data: SelectTabData) => {
            this.setState({ selectedTab2: data.value});}} style={{ justifyContent: 'space-evenly'}}
          >
            <Tab value="1">Global Score</Tab>
            <Tab value="2">Product Score</Tab>
          </TabList>
            <ScoreCard type={this.state.selectedTab2 === "1" ? "GLOBAL" : "PRODUCT"} />
            {this.state.selectedTab2 === "2" &&  <ScoreCard type="PRODUCT"/>} 
            <Button appearance="primary" size="large" style={{ width: '40%', alignSelf: 'center', margin :'10px 0'}}
              onClick={() => this.props.navigate('/request-for-quote')}
            >
              Request Quote
            </Button>
          </div> */}
        </div>
        </>
      }
    </>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return { userData: state.user, conv: state.conversationHistory, llm: state.largeLanguageModel };
}

const mapDispatchToProps = { setCurrentLink, setConversationList, setChat, setConfig, setParameters,
  setDocList, setSelectedLLM, setConversation, setLLMList, setDocPageData, setConvPageData };

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(Workspace));