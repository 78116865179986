import axios from "axios";
const axiosInstance = axios.create({
	baseURL: 'https://testdriveai.recodesolutions.com:8081/api/v1'
  	// baseURL: 'http://testdriveai.recodesolutions.com:8000/api/v1'
	// baseURL: 'http://127.0.0.1:8000/api/v1'
});
let params = new URLSearchParams(window.location.search);
let productId = params.get('productId') ? params.get('productId') : localStorage.getItem('productId');
if(productId){
	axiosInstance.defaults.headers.common['Product-ID'] = productId;
	localStorage.setItem('productId', productId);
} else {
	window.location.href="https://www.recodesolutions.com";
}
const axiosApi = ({ url, method, body, params, headers, responseType} :any ): Promise<any> => axiosInstance({
	url: url,
	method,
	data: body,
	params,
	headers,
	responseType: responseType === undefined ? 'json' : responseType
}).then(result => {
	// console.log("result ", result);
	return { isSuccess: true, body: result.data };
}).catch(axiosError => {
	// console.log("axiosError ", axiosError);
	const err: any = axiosError;
	return {
		isSuccess: false,
		body: err.response?.data || err.message
	};
})

export default axiosApi;